import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconQaBug: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 6H16C16 3.79086 14.2091 2 12 2C9.79087 2 8.00001 3.79086 8.00001 6H7C6.14152 6 5.40936 6.5409 5.12574 7.30049L3.10779 6.03953L2.04795 7.73563L5 9.58028L5 13H2V15H5C5 16.1154 5.26088 17.1698 5.72494 18.1057L2.56546 20.2368L3.68385 21.8948L6.86033 19.7523C8.13906 21.1346 9.96846 22 12 22C14.0315 22 15.861 21.1346 17.1397 19.7523L20.3162 21.8948L21.4345 20.2368L18.2751 18.1057C18.7391 17.1698 19 16.1154 19 15H22V13H19L19 9.58026L21.952 7.73563L20.8922 6.03953L18.8743 7.30048C18.5906 6.54089 17.8585 6 17 6ZM12 4C13.1046 4 14 4.89543 14 6H10C10 4.89543 10.8954 4 12 4ZM11 8H7V15C7 17.419 8.71776 19.4367 11 19.9V8ZM13 8V19.9C15.2822 19.4367 17 17.419 17 15V8H13Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </BaseIcon>
);
