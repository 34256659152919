import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { IconNotifications } from '@/components/ui/icons/IconNotifications';
import { typography } from '@/components/ui/typography';
import { getNotificationsCountToDisplay } from '@/components/platform/Notifications/components/NotificationsButton/helpers';
import styles from '@/components/platform/Notifications/components/NotificationsButton/NotificationsButton.module.scss';

interface Props {
  onClick: () => void;
  unreadNotificationsCount?: number;
  mobilePageOpened?: boolean;
}

export const NotificationsButton: FC<Props> = ({
  unreadNotificationsCount,
  onClick,
  mobilePageOpened,
}) => (
  <Button
    data-qa='notifications-button'
    disabled={mobilePageOpened}
    mode={ButtonMode.TransparentLight}
    size={ButtonSize.Small}
    LeftIcon={IconNotifications}
    className={cn(styles.button, {
      [styles.pageOpenedState]: mobilePageOpened,
    })}
    renderRigthIcon={() => (
      !!unreadNotificationsCount && (
        <div
          className={cn(styles.unreadCountBlock, {
            [styles.buttonDisabledCounter]: mobilePageOpened,
          })}
          data-qa='amount-unread-messages'
        >
          <span className={cn(
            typography.platformTextSmall,
            styles.unreadCountText,
          )}
          >
            {getNotificationsCountToDisplay(unreadNotificationsCount)}
          </span>
        </div>
      )
    )}
    onClick={onClick}
  />
);
