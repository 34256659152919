import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { useSidebarState } from '@/controllers/apollo/apollo.modules/sidebar/sidebar.hooks';
import {
  SidebarToggleComponent,
} from '@/components/platform/SidebarHeader/components/SidebarToggle';
import { HeaderLogo } from '@/components/platform/SidebarHeader/components/HeaderLogo';
import styles from './SidebarHeader.module.scss';

type Props = {
  isBurgerVisible?: boolean;
  shouldAddStylesForMobile?: boolean;
};

export const SidebarHeader: FC<Props> = React.memo(({
  isBurgerVisible,
  shouldAddStylesForMobile,
}) => {
  const [{ sidebarOpen }, toggleSidebar] = useSidebarState();

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActive]: sidebarOpen,
      })}
    >
      <HeaderLogo
        shouldAddStylesForMobile={shouldAddStylesForMobile}
        size={32}
      />

      <div className={styles.divider} />

      <div className={styles.toggleWrapper}>
        <SidebarToggleComponent
          isBurgerVisible={isBurgerVisible}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </div>
  );
});
