import { type FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { IconImage } from '@/components/ui/Image/IconImage';
import goal from '@/images/generated/icons.emoji.goal.s3Key';

export const IconEmojiGoal: FCImage = ({
  size = 24,
  className,
  alt = 'Emoji goal',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={goal}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
