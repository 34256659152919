import React, { type FC } from 'react';
import { type UserStreakBaseFragment } from '@/components/platform/Streaks/graphql/generated/UserStreaks.base.fragment.generated';
import { StreaksCalendar } from '@/components/platform/Streaks/components/StreaksCalendar';
import { ModalHeader } from '@/components/platform/Streaks/components/ModalHeader';
import { StreaksStats } from '@/components/platform/Streaks/components/StreaksStats';
import styles from './StreaksModalContent.module.scss';

interface Props {
  isActive?: boolean;
  activeStreak: UserStreakBaseFragment | null;
}

export const StreaksModalContent: FC<Props> = ({
  activeStreak,
  isActive = true,
}) => (
  <div className={styles.container}>
    <ModalHeader activeStreak={activeStreak} />

    <StreaksCalendar
      isActive={isActive}
      activeStreak={activeStreak}
    />

    <StreaksStats
      activeStreak={activeStreak}
      isActive={isActive}
    />
  </div>
);
