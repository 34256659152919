import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import {
  setSidebarState,
  sidebarState,
} from '@/controllers/platform/sidebar/sidebar.queries';
import { MESSAGES } from '@/lib/constants/messages';
import {
  type SidebarStateQueryResult,
  type ToggleSidebarMutationResult, type ToggleSidebarMutationVariables,
} from '@/controllers/platform/sidebar/sidebar.typedefs';
import { errorHandler } from '@/core/ErrorHandler';

export type SidebarHookOutput = [
  { sidebarOpen: boolean },
  (args: ToggleSidebarMutationVariables) => void
];

export const useSidebarState = (): SidebarHookOutput => {
  const queryResult = useQuery<SidebarStateQueryResult>(sidebarState);

  const { data, loading, error } = queryResult;
  const ready = !(loading || error);

  const content = useMemo(
    () => ((ready && data)
      ? data.sidebarState
      : { sidebarOpen: false }),
    [ready, data],
  );

  const [mutate] = useMutation<ToggleSidebarMutationResult,
  ToggleSidebarMutationVariables>(setSidebarState);

  const { showMessage } = useFlashMessage();

  const toggleSidebar = useCallback(
    async (args: ToggleSidebarMutationVariables) => {
      try {
        await mutate({
          variables: args,
        });
      } catch (e) {
        errorHandler.captureException(e, {
          logMessage: '[Sidebar Error]: Error while setting sidebar state',
          fields: args,
        });

        showMessage(MESSAGES.general.unexpectedError);
      }
    },
    [mutate, showMessage],
  );

  return [content, toggleSidebar];
};
