import { type SuggestionDataItem } from 'react-mentions';
import {
  type ParticipantRole,
  type ParticipantStatus,
} from '@/controllers/graphql/generated';
import { type FileBaseFragment } from '@/controllers/files/File/generated/FileBase.fragment.generated';

export interface MentionSuggestion extends SuggestionDataItem {
  avatar?: FileBaseFragment | null;
  userId?: number;
  role?: ParticipantRole;
  status?: ParticipantStatus;
  description?: string;
}
export type MentionsSuggestFn = (suggestions: MentionSuggestion[]) => void;

export type MentionsSearchFn = (
  searchQuery: string,
  callback: MentionsSuggestFn,
) => Promise<void>;

export enum GroupMentions {
  All = 'all',
  Loading = 'loading',
  Student = 'student',
}
