import { type FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type SvgProps } from '@/components/ui/icons/typedefs';

export const IconFireBest: FC<SvgProps> = (props) => (
  <BaseIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_59777_11566)">
      <ellipse cx="11.3142" cy="12.1967" rx="3.28201" ry="4.35298" fill="transparent" />
      <path d="M16.5481 9.99951C15.1741 6.61662 10.2817 6.43421 11.4632 1.51742C11.5507 1.1526 11.1394 0.870693 10.8068 1.06139C7.62982 2.83575 5.34554 6.39276 7.26223 11.0525C7.41977 11.4339 6.94716 11.7904 6.60583 11.5417C5.02172 10.4058 4.85543 8.77238 4.99546 7.6033C5.04797 7.17215 4.45284 6.96486 4.19903 7.32139C3.60389 8.1837 3 9.57665 3 11.6744C3.33258 16.3175 7.47228 17.7437 8.96013 17.9261C11.0869 18.1831 13.3886 17.81 15.0428 16.3756C16.8632 14.7754 17.5284 12.2216 16.5481 9.99951ZM8.42625 14.1701C9.68654 13.8799 10.3342 13.0176 10.5092 12.2548C10.798 11.0691 9.66904 9.9083 10.4305 8.03445C10.7193 9.58494 13.2924 10.555 13.2924 12.2465C13.3624 14.3442 10.9643 16.1434 8.42625 14.1701Z" fill="url(#paint0_linear_59777_11566)" />
      <path d="M14.37 1.375L15 0L15.625 1.375L17 2L15.625 2.63L15 4L14.37 2.63L13 2L14.37 1.375Z" fill="#E25544" />
      <path d="M2.0275 4.03125L2.5 3L2.96875 4.03125L4 4.5L2.96875 4.9725L2.5 6L2.0275 4.9725L1 4.5L2.0275 4.03125Z" fill="#E25544" />
      <path d="M18.0275 16.0312L18.5 15L18.9688 16.0312L20 16.5L18.9688 16.9725L18.5 18L18.0275 16.9725L17 16.5L18.0275 16.0312Z" fill="#E25544" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_59777_11566" x1="10" y1="1" x2="8" y2="17.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F34D39" />
        <stop offset="1" stopColor="#F57050" />
      </linearGradient>
      <clipPath id="clip0_59777_11566">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
