import React, {
  type FC, useCallback, useEffect, useState, memo,
} from 'react';
import { type ValueType } from 'react-select/src/types';
import { useChatFilterOptions } from '@/components/platform/GlobalSearch/hooks/useChatFilterOptions';
import { type SelectOption, SelectUi } from '@/components/ui/FormElements/FormInputs/Select';
import { useDebounce } from '@/hooks/useDebounce';
import { IconGroup } from '@/components/ui/icons/IconGroup';
import styles from './ChatFilter.module.scss';

interface Props {
  setChatId: (chatId: number | null) => void;
}

const DEBOUNCE = 250;

export const ChatFilter: FC<Props> = memo(({ setChatId }) => {
  const {
    getChatOptions,
    chatOptions,
    loading,
  } = useChatFilterOptions();

  const [chatQuery, setChatQuery] = useState('');
  const [debouncedChatQuery] = useDebounce(chatQuery, DEBOUNCE);

  const onChange = useCallback(
    (option: ValueType<SelectOption<string, string>, false>) => {
      if (option?.value) {
        setChatId(Number(option.value));
      } else {
        setChatId(null);
      }
    },
    [setChatId],
  );

  const chatOptionRendered = useCallback(({ label }) => (
    <p className={styles.selectOption}>
      <IconGroup
        className={styles.icon}
        viewBox="0 -4 24 28"
      />

      {label}
    </p>
  ), []);

  useEffect(() => {
    if (debouncedChatQuery) {
      getChatOptions(debouncedChatQuery);
    }
  }, [debouncedChatQuery, getChatOptions]);

  return (
    <SelectUi
      inputValue={chatQuery}
      onInputChange={setChatQuery}
      onChange={onChange}
      options={chatOptions}
      isLoading={loading}
      isClearable
      formatOptionLabel={chatOptionRendered}
      placeholder="In: Select chat"
    />
  );
});
