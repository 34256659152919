import React, { type FC, memo, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { idX } from '@/lib/helpers/idx';
import { useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { IconOff } from '@/components/ui/icons/IconOff';
import { typography } from '@/components/ui/typography';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import styles from './SignedAsUser.module.scss';
import { type AdminUserFragment } from './generated/AdminUser.fragment.generated';

interface Props {
  user: AuthUserFragment;
  adminUser: AdminUserFragment;
  onSignOut: () => void;
}

export const SignedAsUser: FC<Props> = memo((props) => {
  const {
    user,
    adminUser,
    onSignOut,
  } = props;

  const { firstName, lastName, email } = user;
  const {
    firstName: adminFirstName,
    lastName: adminLastName,
    email: adminEmail,
  } = adminUser;

  const { t } = useTranslation([I18N_CODES.common]);

  const userName = useMemo(() => {
    const renderEmail = firstName || lastName
      ? `(${email})`
      : email;
    const name = [firstName, lastName].filter(idX).join(' ');

    return [name, renderEmail].join(' ');
  }, [firstName, lastName, email]);

  const adminName = useMemo(() => (
    adminFirstName || adminLastName || adminEmail
  ), [adminFirstName, adminLastName, adminEmail]);

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.info, typography.platformH4)}>
        {t(`${I18N_CODES.common}:signed_as_user`, { name: adminName })}
        {' '}
        <span className={styles.userName}>{userName}</span>
      </div>

      <Button
        type="button"
        onClick={onSignOut}
        mode={Button.mode.Primary}
        size={Button.size.Small}
        RightIcon={IconOff}
      />

      <Divider
        referral={DividerReferrals.Vertical}
        className={styles.divider}
      />
    </div>
  );
});
