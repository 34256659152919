import React, { type FC, useCallback } from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { typography } from '@/components/ui/typography';
import { ROUTES } from '@/controllers/router/router.contants';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useFindUncommentedIdleActivitiesQuery } from '@/components/platform/TimeTracker/graphql/generated/findUncommentedIdleActivities.query.generated';
import { analyticsSDK } from '@/controllers/analytics';
import { IconEmojiWarning } from '@/components/ui/IconEmojies/IconEmojiWarning';
import styles from './ContractorTimelineButton.module.scss';

interface Props {
  mentorId: number;
}

export const ContractorTimelineButton: FC<Props> = ({ mentorId }) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const { data } = useFindUncommentedIdleActivitiesQuery();

  const sendEvent = useCallback(() => {
    analyticsSDK.matchWorkingTime.sendContractorTimelineOpenedByMentorEvent({});
  }, []);

  const idleActivities = data?.findUncommentedIdleActivities
    || [];

  const shouldShowActionRequiredWarning = idleActivities.length > 0;

  return (
    <div className={cn(styles.wrapper, 'p-16')}>

      {shouldShowActionRequiredWarning && (
        <>
          <div className={cn(styles.titleWrapper, 'mb-4')}>
            <div className={styles.iconWrapper}>
              <IconEmojiWarning size={16} />
            </div>

            <h4 className={typography.platformH4} data-qa="action-required">
              {t(`${I18N_CODES.common}:action_required_title`)}
            </h4>
          </div>

          <p className={cn(typography.platformTextSmall, 'mb-16')}>
            {t(`${I18N_CODES.common}:action_required_text`)}
          </p>
        </>
      )}

      <Button
        mode={Button.mode.Secondary}
        data-qa="contractor-timeline-button"
        hasFullWidth
        onClick={sendEvent}
        href={`${ROUTES.contractors.timeline}/${mentorId}`}
        text={t(`${I18N_CODES.common}:view_full_timeline_button`)}
      />
    </div>
  );
};
