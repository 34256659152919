import React, { type FC, memo } from 'react';
import { FormField } from '@/components/ui/FormElements/FormField';
import { type RatingsFeedbackType } from '@/controllers/graphql/generated';
import { type FeedbackCardsOptions } from '@/components/platform/LikeRating/hooks/useReportProblemCardsOptions';
import { ReportProblemCard } from '@/components/platform/LikeRating/ReportProblemOptionCard/ReportProblemCard';
import { type FormValues } from '../ReportProblemForm.typedefs';

interface Props {
  feedbackCard: FeedbackCardsOptions;
  change: (name: keyof FormValues, value: RatingsFeedbackType) => void;
  dataQa?: string;
}

export const ReportProblemCardField: FC<Props> = memo(({
  feedbackCard,
  change,
  dataQa,
}) => (
  <FormField
    renderInput={(inputProps) => (
      <ReportProblemCard
        key={feedbackCard.type}
        title={feedbackCard.title}
        subtitle={feedbackCard.subtitle}
        LeftIcon={feedbackCard.leftIcon}
        RightIcon={feedbackCard.rightIcon}
        onClick={() => {
          change('feedbackType', feedbackCard.type);
          feedbackCard.onClick();
        }}
        {...inputProps}
        className='mb-8'
        dataQa={dataQa}
      />
    )}
    name='feedbackType'
  />
));
