import { type ApolloCache } from '@apollo/client';
import { type UserAchievementStatsFragment, type UserAchievementForIconFragment } from '@/modules/achievements/communication/generated/achievements.fragments.generated';
import { AchievementsDataDocument, type AchievementsDataQuery } from '@/modules/achievements/communication/generated/achievements.queries.generated';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { AchievementRarity } from '@/controllers/graphql/generated';
import { MAX_ACHIEVEMENTS_ON_CARD } from '@/modules/achievements/constants';
import { type UserAchievementFullFragment } from '@/modules/achievements/communication/generated/userAchievementFull.fragment.generated';

export const updateAchievementsCardCache = (
  cache: ApolloCache<any>,
  authUser: AuthUserFragment | null,
  unlockedAchievement: UserAchievementFullFragment,
) => {
  const achievementsCardQuery = cache.readQuery<AchievementsDataQuery>({
    query: AchievementsDataDocument,
    variables: {
      userId: authUser?.id ?? 0,
    },
  });

  if (
    achievementsCardQuery?.authUser
    && achievementsCardQuery?.getLatestUserAchievements
  ) {
    const {
      authUser: authUserFromCache,
      getLatestUserAchievements: latestAchievementsFromCache,
    } = achievementsCardQuery;

    const {
      achievementsStats,
    } = authUserFromCache.studyingStats;
    const unlockedRareCount = unlockedAchievement
      .achievement.rarity === AchievementRarity.Rare
      ? achievementsStats.unlockedRareCount + 1
      : achievementsStats.unlockedRareCount;

    const newAchievement: UserAchievementForIconFragment = {
      __typename: 'UserAchievement',
      id: unlockedAchievement.id,
      achievement: {
        __typename: 'Achievement',
        id: unlockedAchievement.achievement.id,
        iconUnlocked: unlockedAchievement.achievement.iconUnlocked
          ? {
            __typename: 'File',
            id: unlockedAchievement.achievement.iconUnlocked?.id,
            url: unlockedAchievement.achievement.iconUnlocked?.url,
          }
          : null,
      },
    } as const;

    const newLatestAchievements = [
      newAchievement,
      ...latestAchievementsFromCache,
    ].slice(0, MAX_ACHIEVEMENTS_ON_CARD);

    const authUserWithAchievementsStats: UserAchievementStatsFragment = {
      ...authUserFromCache,
      studyingStats: {
        ...authUserFromCache.studyingStats,
        achievementsStats: {
          ...achievementsStats,
          unlockedCount: achievementsStats.unlockedCount + 1,
          unlockedRareCount,
        },
      },
    } as const;

    const updatedAchievementsCard = {
      authUser: authUserWithAchievementsStats,
      getLatestUserAchievements: newLatestAchievements,
    };

    cache.writeQuery<AchievementsDataQuery>({
      query: AchievementsDataDocument,
      variables: {
        userId: authUser?.id ?? 0,
      },
      data: updatedAchievementsCard,
    });
  }
};
