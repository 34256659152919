import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconBurger: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" fill="currentColor" />
  </BaseIcon>
);
