import React, { memo, type FC } from 'react';
import { Tag } from '@/components/ui/Tag';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { type CourseAdvantage } from '@/components/platform/PlatformConsultation/typedefs';
import { CONSULTATION_MODAL_DATA_QA } from '@/components/platform/PlatformConsultation/constants';
import styles from './CourseAdvantages.module.scss';

type Props = {
  advantages: CourseAdvantage[];
};

export const CourseAdvantages: FC<Props> = memo(({
  advantages,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  return (
    <section data-qa='course-advantages-section'>
      <h2 className={cn(typography.platformH2, 'mb-12')}>
        {t(`${I18N_CODES.common}:platform_consultation.advantages.title`)}
      </h2>

      <ul
        className={styles.container}
        data-qa={CONSULTATION_MODAL_DATA_QA.COURSE_ADVANTAGES}
      >
        {advantages.map(({ title, icon: Icon, iconColor }) => (
          <li key={title}>
            <Tag
              text={t(`${I18N_CODES.common}:${title}`)}
              IconElement={() => <Icon color={iconColor} />}
            />
          </li>
        ))}
      </ul>
    </section>
  );
});
