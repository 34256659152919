import React, {
  type FC, memo, useCallback,
} from 'react';
import { Button, type ButtonProps } from '@/components/ui/Button';
import { IconDiamond } from '@/components/ui/icons/IconDiamond';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { analyticsSDK } from '@/controllers/analytics';
import { useBecomeStudentModal } from '@/components/platform/PlatformConsultation/BecomeStudentButton/useBecomeStudentModal';

type Props = ButtonProps & {
  dataQa?: string;
};

export const BecomeStudentButton: FC<Props> = memo(({
  dataQa = 'become-student-button',
  ...buttonProps
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    isModalAvailable,
    openModal,
    BecomeStudentModal,
  } = useBecomeStudentModal();

  const onButtonClick = useCallback(() => {
    analyticsSDK.platformConsultation.sendBecomeStudentButtonClickedEvent({});

    openModal();
  }, [openModal]);

  if (!isModalAvailable) {
    return null;
  }

  return (
    <>
      <Button
        mode={Button.mode.BrandPrimary}
        text={t(`${I18N_CODES.common}:become_student_btn`)}
        LeftIcon={IconDiamond}
        onClick={onButtonClick}
        {...buttonProps}
        dataQa={dataQa}
      />

      <BecomeStudentModal />
    </>
  );
});
