import { type useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { RatingItemType } from '@/controllers/graphql/generated';
import { possibleTypes } from '@/controllers/apollo/apollo.constants';
import { updateRatingNotificationCache } from '@/components/platform/Notifications/helpers/updateRatingNotificationCache';
import { type RateEntityMutation } from '@/controllers/graphql/generated/rateEntity.mutation.generated';

type UpdateCacheCallback = (
  client: ReturnType<typeof useApolloClient>,
  data: RateEntityMutation,
  cacheEntityId: number,
) => void;
export const cacheUpdaterItemTypes: Record<
  RatingItemType,
  UpdateCacheCallback | null
> = {
  [RatingItemType.TestTasks]: (client, data, cacheEntityId) => {
    const {
      upsertRating: {
        rating: newRating,
      },
    } = data;

    possibleTypes.TestTaskAttempt.forEach((typeName) => {
      const identifier = client.cache.identify({
        __typename: typeName,
        id: cacheEntityId,
      });

      client.cache.modify({
        id: identifier,
        fields: {
          ...(newRating && { personalRating: () => newRating }),
        },
      });
    });
  },
  [RatingItemType.CourseTopics]: null,
  [RatingItemType.LearnVideos]: (client, data, cacheEntityId) => {
    const videoRatingFragment = gql`
      fragment VideoRating on LearnVideo {
        id
        userRating(userId: $userId)
      }
    `;

    const identifier = client.cache.identify({
      __typename: 'LearnVideo',
      id: cacheEntityId,
    });

    client.cache.writeFragment({
      id: identifier,
      fragment: videoRatingFragment,
      fragmentName: 'VideoRating',
      data: {
        id: cacheEntityId,
        userRating: data.upsertRating.rating,
      },
    });
  },
  [RatingItemType.Vacancies]: null,
  [RatingItemType.TestTaskLoading]: null,
  [RatingItemType.TestTaskAiSuggestions]: null,
  [RatingItemType.AiCodeReviews]: null,
  [RatingItemType.AiAssistantQuickActions]: null,
  [RatingItemType.CourseTopicLoading]: null,
  [RatingItemType.Hirings]: null,
  [RatingItemType.ProofOfRevenueProcessing]: null,
  [RatingItemType.HiringsBadCandidate]: null,
  [RatingItemType.Module]: null,
  [RatingItemType.TasksReviews]: updateRatingNotificationCache,
  [RatingItemType.TechChecks]: updateRatingNotificationCache,
  [RatingItemType.Qna]: updateRatingNotificationCache,
  [RatingItemType.EnglishLessons]: updateRatingNotificationCache,
};
