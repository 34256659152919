import React, { type FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { Dropdown } from '@/components/common/Dropdown';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { ProfileDropdownContentItems } from '@/components/platform/ProfileDropdown/ProfileDropdownContentItems';
import { Selectors } from '@/lib/selectors';
import { ProfileAvatar } from '../ProfileAvatar';
import styles from './ProfileDropdown.module.scss';

export const ProfileDropdown: FC = memo(
  () => {
    const [authUser] = useAuthUser({ ssr: true });

    if (!authUser) {
      return null;
    }

    return (
      <div className={styles.wrapper} data-qa='profile-dropdown'>
        <Dropdown
          isFullWidth
          className={styles.container}
          renderTrigger={(onClick) => (
            <button
              className={styles.triggerButton}
              onClick={onClick}
              data-qa='dropdown-open-button'
            >
              <ProfileAvatar />
            </button>
          )}
          renderContent={({ isActive }) => (
            <ul
              data-qa='dropdown-content'
              className={cn(
                styles.dropdownContent,
                {
                  [Selectors.DropdownOpened]: isActive,
                },
              )}
            >
              <ProfileDropdownContentItems isOpened={isActive} />
            </ul>
          )}
        />
      </div>
    );
  },
);
