import React, { type FC } from 'react';
import styles from './Input.module.scss';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export const Input: FC<Props> = ({
  value,
  onChange,
  placeholder = 'Search on Mate academy',
}) => (
  <input
    value={value}
    onChange={onChange}
    className={styles.input}
    data-qa="search-input-field"
    placeholder={placeholder}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus
  />
);
