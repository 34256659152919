import { create } from 'zustand';
import { type GetterSetterPair } from '@/lib/helpers/utility-types';
import { type MessageTypes } from '@/lib/constants/messages';
import { type AchievementModalPayload } from '@/components/platform/Achievements/components/AchievementGamificationModalContent';
import { type StreakModalPayload } from '@/components/platform/Streaks/components/StreaksGamificationModalContent';
import { EMPTY_ARRAY } from '@/constants';

interface AchievementReceivedAction {
  id: number;
  type: MessageTypes.Achievement;
  payload: AchievementModalPayload;
}

interface StreakReceivedActions {
  id: number;
  type: MessageTypes.Streak;
  payload: StreakModalPayload;
}

export type GamificationActions =
  | AchievementReceivedAction
  | StreakReceivedActions;

const getModalKey = (modal: GamificationActions) => (
  `${modal.id}-${modal.type}`
);

type GamificationModalStore =
  GetterSetterPair<'shouldRenderModals', boolean> & {
  modals: GamificationActions[];
  addModal: (modal: GamificationActions) => void;
  removeModal: (modal: GamificationActions) => void;
};

export const useGamificationModalStore = create<
    GamificationModalStore
>()((set, get) => ({
  shouldRenderModals: false,
  setShouldRenderModals: (shouldRenderModals) => {
    set({ shouldRenderModals });
  },
  modals: EMPTY_ARRAY,
  addModal: (modal: GamificationActions) => {
    const { modals } = get();

    if (
      !modals.find((curr) => (
        getModalKey(curr) === getModalKey(modal)
      ))
    ) {
      set({ modals: [...modals, modal] });
    }
  },
  removeModal: (modal: GamificationActions) => {
    const { modals } = get();

    const filteredModals = modals.filter((curr) => (
      getModalKey(curr) !== getModalKey(modal)
    ));

    set({ modals: filteredModals });
  },
}));
