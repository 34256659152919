import { type Maybe, UserEnglishLevel } from '@/controllers/graphql/generated';
import { STUDENT_NORMALIZE_ENGLISH_LEVEL } from '@/controllers/user/user.constants';

export interface EnglishLevelOptions {
  value: UserEnglishLevel;
  label: string;
}

export const getUserEnglishLevelOptions = (
  currentEnglishLevel?: Maybe<UserEnglishLevel>,
): EnglishLevelOptions[] => {
  const allLevels = Object.values(UserEnglishLevel);

  const currentLevelIndex = allLevels
    .map((level, index) => ({ level, index }))
    .find(({ level }) => level === currentEnglishLevel)
    ?.index;

  const availableLevels = currentLevelIndex
    ? allLevels.filter((_, index) => index >= currentLevelIndex)
    : allLevels;

  return availableLevels.map((level) => ({
    value: level,
    label: STUDENT_NORMALIZE_ENGLISH_LEVEL[level],
  }));
};
