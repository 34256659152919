import React, {
  memo, type FC,
} from 'react';
import { Button, type ButtonProps } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { usePaywall } from '@/components/platform/SubscriptionProduct/hooks/usePaywallModal';
import { PaywallLocations } from '@/controllers/analytics/generated';
import { Paywall } from '@/components/platform/SubscriptionProduct/components/Paywall';

export const KnowelyUnlockCoursesButton: FC<ButtonProps> = memo((props) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    isPaywallOpened,
    openPaywall,
    closePaywall,
  } = usePaywall({
    paywallLocation: PaywallLocations.UnlockAllCourses,
  });

  return (
    <>
      <Button
        mode={Button.mode.BrandPrimary}
        text={t(`${I18N_CODES.common}:unlock_courses_button`)}
        data-qa="unlock-courses-button"
        onClick={openPaywall}
        {...props}
      />

      <Paywall
        paywallLocation={PaywallLocations.UnlockAllCourses}
        isOpen={isPaywallOpened}
        closePaywall={closePaywall}
      />
    </>
  );
});
