import React, { type FC, type ReactElement, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { Button, ButtonMode } from '@/components/ui/Button';
import { typography } from '@/components/ui/typography';
import { HumanReadableDate } from '@/components/platform/common/HumanReadableDate';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { type RatingItemType, type UserNotificationsCustomImages } from '@/controllers/graphql/generated';
import { PlatformEvents } from '@/controllers/platform/platform.events';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { type UserNotificationSenderBaseFragment } from '@/components/platform/Notifications/graphql/generated/UserNotificationSenderBase.fragment.generated';
import { NotificationIcon } from '@/components/platform/Notifications/components/NotificationIcon';
import { NotificationLikeRating } from '@/components/platform/Notifications/components/NotificationLikeRating';
import styles from './BasicNotification.module.scss';

interface Props {
  notificationId: number;
  message: string;
  createdAt: Date;
  isUnread: boolean;
  sender?: UserNotificationSenderBaseFragment | null;
  customImageType?: UserNotificationsCustomImages;
  actionText?: string;
  actionLink?: string | null;
  renderIcon?: () => ReactElement;
  ratingData?: {
    relatedId: number;
    relatedType: RatingItemType;
    userRating: number;
  };
}

export const BasicNotification: FC<Props> = ({
  notificationId,
  message,
  createdAt,
  isUnread,
  sender,
  actionText,
  actionLink,
  renderIcon,
  ratingData,
  customImageType,
}) => {
  const { language } = useSubDomainContext();
  const { t } = useTranslation([I18N_CODES.platformNotifications]);

  const onClickActionLink = useCallback(() => {
    PlatformEvents.sendEvent(
      PlatformEvents.events.notificationsActionLinkClicked,
      {
        notificationId,
      },
    );
  }, [notificationId]);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.unread]: isUnread,
      })}
    >
      <div className='mr-8'>
        {renderIcon && renderIcon()}

        {!renderIcon && (
          <NotificationIcon
            sender={sender}
            customImageType={customImageType}
          />
        )}
      </div>
      <div className={styles.content}>
        {sender?.username && (
          <h1 className={cn(typography.platformH4, 'mb-4')}>
            {sender?.username}
          </h1>
        )}

        <h2 className={cn(typography.platformTextSecondary, 'mb-8')}>{message}</h2>
        <p
          className={cn(typography.platformTextSmall, styles.time, 'mb-12')}
        >
          <HumanReadableDate language={language} time={createdAt} />
        </p>

        {actionLink && (
          <Button
            data-qa="notification_action_link"
            text={actionText || t(`${I18N_CODES.platformNotifications}:notification_action_button_text`)}
            mode={ButtonMode.Secondary}
            className='mb-12'
            hasFullWidth
            href={actionLink}
            onClick={onClickActionLink}
            target='_blank'
            rel="noopener noreferrer"
          />
        )}

        {ratingData && (
          <div className='mb-12'>
            <NotificationLikeRating
              notificationId={notificationId}
              relatedId={ratingData.relatedId}
              relatedType={ratingData.relatedType}
              buttonClassName={styles.ratingButton}
              userRating={ratingData.userRating}
            />
          </div>
        )}
      </div>
    </div>
  );
};
