import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsApplyingLight,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying.light';
import {
  ChubbsApplyingDark,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying.dark';

export const ChubbsApplying: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={ChubbsApplyingLight}
    DarkChubbs={ChubbsApplyingDark}
    {...props}
  />
));
