import { memo } from 'react';
import { cn } from '@/lib/classNames';
import { IconArrowDown } from '@/components/ui/icons/IconArrowDown';
import styles from './DropdownArrows.module.scss';

interface Props {
  active: boolean;
}

export const DropdownArrows = memo<Props>(({
  active,
}) => (
  <span className={cn(
    styles.wrapper,
    {
      [styles.active]: active,
    },
  )}
  >
    <span className={cn(
      styles.arrow,
      styles.arrow_down,
    )}
    >
      <IconArrowDown />
    </span>
    <span className={cn(
      styles.arrow,
      styles.arrow_up,
    )}
    >
      <IconArrowDown />
    </span>
  </span>
));
