import { useCallback } from 'react';
import { logger } from '@/core/Logger/Logger';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { type CreateSubscriptionPayment } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/typedefs';
import { type ProcessKnowelySubscriptionFreeTrialMutation, useProcessKnowelySubscriptionFreeTrialMutation } from '@/components/paymentProviders/graphql/generated/processKnowelySubscriptionFreeTrial.mutation.generated';
import { type CreateKnowelySubscriptionPaymentMutation, useCreateKnowelySubscriptionPaymentMutation } from '@/components/paymentProviders/graphql/generated/createKnowelySubscrpitionPayment.mutation.generated';
import { mapSubscriptionPaymentResult } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/utils/mapSubscriptionPaymentResult';

interface Options {
  planId?: number;
  pricingOptionId?: number;
  successUrl?: string;
  isBuiltInCheckout?: boolean;
  isFreeTrialPlan?: boolean;
}

export const useProceedWithSubscription = ({
  planId,
  pricingOptionId,
  successUrl,
  isBuiltInCheckout = false,
  isFreeTrialPlan = false,
}: Options) => {
  const flashMessage = useFlashMessage();
  const [authUser] = useAuthUser({
    ssr: true,
  });

  const { t } = useTranslation(I18N_CODES.payment);

  const handleCompletedSubscription = useCallback(
    (data: CreateKnowelySubscriptionPaymentMutation
      | ProcessKnowelySubscriptionFreeTrialMutation) => {
      const result = mapSubscriptionPaymentResult(data, isFreeTrialPlan);

      if (result?.redirect && !isBuiltInCheckout) {
        logger.child('SubscriptionPlanCard').info(
          `Subscription payment has been redirected to [CHECKOUT] page`,
          {
            redirectUrl: result.redirect,
            userId: authUser?.id,
          },
        );
        window.location.href = result.redirect;
      }
    },
    [
      authUser?.id,
      isBuiltInCheckout,
      isFreeTrialPlan,
    ],
  );

  const [
    createSubscription,
    {
      data: paymentData,
      loading: paymentLoading,
      called: createSubscriptionCalled,
    },
  ] = useCreateKnowelySubscriptionPaymentMutation({
    onCompleted: handleCompletedSubscription,

    onError(error) {
      flashMessage.showMessage({
        type: MESSAGE_TYPES.error,
        heading: t(`${I18N_CODES.payment}:subscription_payment_error.title`),
        text: error.message,
      });
    },
  });

  const [
    createSetupIntent,
    {
      data: setupData,
      loading: setupLoading,
      called: setupIntentCalled,
    },
  ] = useProcessKnowelySubscriptionFreeTrialMutation({
    onCompleted: handleCompletedSubscription,

    onError(error) {
      flashMessage.showMessage({
        type: MESSAGE_TYPES.error,
        heading: t(`${I18N_CODES.payment}:subscription_payment_error.title`),
        text: error.message,
      });
    },
  });

  const createSubscriptionPayment: CreateSubscriptionPayment = (
    useCallback(async () => {
      if (!planId) {
        flashMessage.showMessage({
          type: MESSAGE_TYPES.error,
          heading: t(`${I18N_CODES.payment}:subscription_payment_error.title`),
          text: t(`${I18N_CODES.payment}:subscription_payment_error.no_subscription_plan_selected`),
        });

        return null;
      }

      const mutation = isFreeTrialPlan
        ? createSetupIntent
        : createSubscription;

      const mutationResult = await mutation({
        variables: {
          planId,
          pricingOptionId,
          successUrl,
          isInternalCheckout: isBuiltInCheckout,
        },
      });

      return mapSubscriptionPaymentResult(mutationResult.data, isFreeTrialPlan);
    }, [
      createSubscription,
      createSetupIntent,
      flashMessage,
      planId,
      pricingOptionId,
      successUrl,
      isBuiltInCheckout,
      t,
      isFreeTrialPlan,
    ])
  );

  const loading = isFreeTrialPlan
    ? setupLoading
    : paymentLoading;

  const called = isFreeTrialPlan
    ? setupIntentCalled
    : createSubscriptionCalled;

  const result = mapSubscriptionPaymentResult(
    setupData ?? paymentData,
    isFreeTrialPlan,
  );

  return {
    createSubscriptionPayment,
    loading,
    called,
    result,
  };
};
