import { type NaturalShadowProps } from '@/components/ui/NaturalShadow';

export const MAX_VISIBLE_ACHIEVEMENTS_COUNT = 8;
export const ICON_SIZE = 64;
export const SHADOW_PROPS: NaturalShadowProps = {
  x: 1,
  y: 3,
  blur: 5,
  opacity: 0.6,
};
