import React, {
  type FC, useCallback,
} from 'react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import {
  Button,
  ButtonMode,
  ButtonSize,
  ButtonStyling,
} from '@/components/ui/Button';
import { AchievementsEvents } from '@/controllers/achievements/achievements.events';
import { TitleBlockHarnessSelectors } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/TitleBlock/TitleBlock.harness';
import { ROUTES } from '@/controllers/router/router.contants';
import styles from './TitleBlock.module.scss';

export const TitleBlock: FC = () => {
  const { t } = useTranslation([I18N_CODES.achievements]);

  const handleButtonClick = useCallback(() => {
    AchievementsEvents.sendEvent(
      AchievementsEvents.events.blockShowAllClicked,
      {},
    );
  }, []);

  return (
    <div
      data-qa={TitleBlockHarnessSelectors.DataQa}
      className={styles.wrapper}
    >
      <h3
        data-qa={TitleBlockHarnessSelectors.Title}
        className={cn(typography.platformH3, styles.title)}
      >
        {t(`${I18N_CODES.achievements}:achievements_block_title`)}
      </h3>

      <Button
        dataQa={TitleBlockHarnessSelectors.ShowAllButton}
        text={t(`${I18N_CODES.achievements}:achievements_block_link`)}
        size={ButtonSize.Small}
        mode={ButtonMode.TransparentLight}
        styling={ButtonStyling.Gray}
        className={styles.button}
        href={ROUTES.achievements}
        onClick={handleButtonClick}
      />
    </div>
  );
};
