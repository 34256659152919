import { useCallback } from 'react';
import { useSearchUsersLazyQuery } from '@/components/platform/GlobalSearch/graphql/generated/searchUsers.guery.generated';
import { type SearchableUserFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableUser.fragment.generated';

export const useUserFilterOptions = () => {
  const [loadUsers, { data, loading }] = useSearchUsersLazyQuery();

  const getUserOptions = useCallback((inputValue = '') => {
    loadUsers({ variables: { query: inputValue } });
  }, [loadUsers]);

  const userOptions = data?.searchByQuery.matches
    .filter((match) => match.entity.__typename === 'SearchableUser')
    .map((match) => ({
      value: String((match.entity as SearchableUserFragment).user.id),
      label: (match.entity as SearchableUserFragment).user,
    }));

  return {
    getUserOptions,
    userOptions,
    loading,
  };
};
