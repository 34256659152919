import { useCallback } from 'react';
import {
  type GamificationActions,
  useGamificationModalStore,
} from '@/hooks/useGamificationModalStore';
import { showMessage } from '@/hooks/useFlashMessage';
import { type BaseFlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { useIsFeaturesEnabled } from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { Features } from '@/controllers/features/features.constants';

type Options = {
  modalOptions: GamificationActions;
  flashMessageOptions: BaseFlashMessage;
};

type ReturnType = {
  showGamificationNotification: (options: Options) => void;
};

export const useShowGamificationNotification = (): ReturnType => {
  const {
    addModal,
    shouldRenderModals,
  } = useGamificationModalStore();

  const isFeatureEnabled = useIsFeaturesEnabled(
    Features.GamificationNotifyingMobileModals,
  );

  const showGamificationNotification = useCallback((options: Options) => {
    const {
      modalOptions,
      flashMessageOptions,
    } = options;

    if (shouldRenderModals && isFeatureEnabled) {
      addModal(modalOptions);
    } else {
      showMessage(flashMessageOptions);
    }
  }, [isFeatureEnabled, addModal, shouldRenderModals]);

  return { showGamificationNotification };
};
