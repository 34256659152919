import {
  type FC,
  useState,
  useMemo,
} from 'react';
import { PopUp } from '@/components/ui/PopUp';
import { PopUpAlignment, PopUpPlacement, PopUpPositioner } from '@/components/ui/PopUp/PopUp.helpers';
import { DROPDOWN_INDENT } from '@/components/ui/DropdownMenu/DropdownMenu.constants';
import { type DropdownMenuProps } from './DropdownMenu.typedefs';
import { DropdownMenuItem } from './DropdownMenuItem';
import styles from './DropdownMenu.module.scss';

type Props = DropdownMenuProps;

export const DropdownMenu: FC<Props> = ({
  items,
  onClose,
  parentNode = null,
  position,
  portalNode,
  popUpProps,
  dataQa,
}) => {
  const [childNode, setChildNode] = useState<HTMLUListElement | null>(null);

  const popUpPositioner = useMemo(() => {
    if (!childNode) {
      return null;
    }

    const childRect = childNode.getBoundingClientRect();

    return new PopUpPositioner({
      popupHeight: childRect.height,
      popupWidth: childRect.width,
      popupIndent: DROPDOWN_INDENT,
      preferredHorizontalAlignment: PopUpAlignment.Right,
      preferredVerticalAlignment: PopUpAlignment.Top,
      preferredPlacement: PopUpPlacement.Bottom,
      ...popUpProps,
    });
  }, [childNode, popUpProps]);

  const calculatedPosition = useMemo(
    () => {
      if (!popUpPositioner) {
        return PopUpPositioner.DEFAULT_POSITION;
      }

      return popUpPositioner.getPosition(parentNode?.getBoundingClientRect());
    },
    [popUpPositioner, parentNode],
  );

  const resultingPosition = position || calculatedPosition;

  const filteredItems = useMemo(() => (
    items.filter((item) => !item.skip)
  ), [items]);

  return (
    <PopUp
      onClose={onClose}
      position={resultingPosition}
      portalNode={portalNode}
      dataQa={dataQa ?? 'dropdown-menu-popup'}
    >
      <ul
        ref={setChildNode}
        className={styles.list}
      >
        {filteredItems.map((dropdownItem, index) => (
          <DropdownMenuItem
            key={dropdownItem.text}
            dataQA={dropdownItem.text}
            isLast={index === filteredItems.length - 1}
            {...dropdownItem}
          />
        ))}
      </ul>
    </PopUp>
  );
};
