import { type FCIcon } from '@/components/ui/icons/typedefs';
import {
  type FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';

export enum ModalStep {
  MainTrack = 'mainTrack',
  AlternativeTrack = 'alternativeTrack',
}

type ChangeModalStepHandler = (step: ModalStep) => void;

export interface PlatformConsultationButton {
  translate: string;
  dataQa: string;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  tooltipTranslate?: string;
}

export type ModalStepButtons = Record<
  ModalStep,
  {
    PRIMARY: PlatformConsultationButton;
    SECONDARY: PlatformConsultationButton | null;
  }
>;

export type GetModalButtonOptions = {
  changeStepHandler: ChangeModalStepHandler;
  hasConsultationBeenRequested: boolean;
  subDomain: string;
  hasDomainActiveFtCourses: boolean;
  courseSlug?: string;
  activeCourse?: FtCourseWithMinimalCourseApplicationInfoFragment;
};

export enum PlatformConsultationSource {
  Platform = 'platform',
  LimitedAccessBanner = 'limited_access_banner',
  PlatformLockedBasicsButton = 'platform_locked_basics',
}

export type CourseAdvantage = {
  title: string;
  icon: FCIcon;
  iconColor: string;
};
