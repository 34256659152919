import { useCallback, type FC } from 'react';
import { ComponentWrapper } from '@/components/ui/helpers';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { Button, type ButtonProps } from '@/components/ui/Button';
import { truthy } from '@/lib/helpers/functional';
import { type WrapperType } from '@/typedefs/common';

interface CustomTooltipProps {
  tooltipText?: string;
  tooltipWidthMode?: ToolTipWidthMode;
}

type Props = ButtonProps & CustomTooltipProps;

export const ButtonWithTooltip: FC<Props> = (props) => {
  const {
    tooltipText,
    tooltipWidthMode = ToolTipWidthMode.Max,
    ...buttonProps
  } = props;

  const renderWrapper: WrapperType = useCallback((children) => (
    <ToolTip text={tooltipText || ''} widthMode={tooltipWidthMode}>
      {children}
    </ToolTip>
  ), [
    tooltipText,
    tooltipWidthMode,
  ]);

  return (
    <ComponentWrapper
      shouldWrapChildren={truthy(tooltipText)}
      wrapper={renderWrapper}
    >
      <Button {...buttonProps} />
    </ComponentWrapper>
  );
};
