import {
  useEffect, useState, type Dispatch, type SetStateAction, useCallback,
} from 'react';
import scrollBarWidth from 'scrollbarwidth';
import { debounce } from '@/lib/helpers/debounce';
import { useKeyPress } from '@/controllers/keyPress/keyPress.hooks/useKeyPress';
import { KeyCodes } from '@/controllers/keyPress/keyPress.typedefs';
import { useRouteChangeCallback } from '@/controllers/router/router.hooks/useRouteChangeCallback';

interface Output {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
}

const toggleScrollbarWidth = (toggle: boolean) => {
  const { documentElement } = document;

  const hasScrollbar = (
    documentElement.scrollHeight > documentElement.clientHeight
  );

  if (!hasScrollbar) {
    documentElement.style.removeProperty('--scrollbar-width');

    return;
  }

  documentElement.style.setProperty(
    '--scrollbar-width',
    toggle
      ? `${scrollBarWidth()}px`
      : '0px',
  );
};

const toggleBodyScroll = (toggle: boolean) => {
  document.documentElement.classList.toggle('has-modal', toggle);
};

export const useBodyScrollLock = (initialValue: boolean, options: {
  resize: boolean;
} = {
  resize: false,
}): Output => {
  const [active, setActive] = useState(initialValue);

  const toggleOnResize = useCallback(() => {
    if (!active) {
      return;
    }

    const toggle = window
      .matchMedia(`(min-width: 64em)`)
      .matches;

    if (toggle) {
      setActive(false);
    }
  }, [active]);

  const debouncedToggle = debounce(toggleOnResize, 100);

  useKeyPress(KeyCodes.Escape, () => {
    setActive(false);
  });

  useEffect(() => {
    toggleBodyScroll(active);
    toggleScrollbarWidth(active);

    if (!options.resize) {
      return () => null;
    }

    window.addEventListener('resize', debouncedToggle, true);

    return () => {
      debouncedToggle.clear();
      toggleBodyScroll(false);
      window.removeEventListener('resize', debouncedToggle, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useRouteChangeCallback(() => {
    setActive(false);
  });

  return { active, setActive };
};
