import React, { type FC, memo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import styles from '@/components/platform/SidebarHeader/SidebarHeader.module.scss';
import { CurrentThingPlatformLogo } from '@/components/platform/SidebarHeader/components/CurrentThingPlatformLogo';
import { Link, useTranslation } from '@/middleware/i18n';
import { Features } from '@/controllers/features/features.constants';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useStaticFilesDeprecated } from '@/controllers/staticFiles/staticFiles.hooks/useStaticFilesDeprecated';
import { STATIC_IMAGES_DEPRECATED } from '@/controllers/staticFiles/staticFiles.constants';
import { useActiveRoute } from '@/components/platform/Learn/pages/CourseTopic/hooks/useActiveRoute';
import { ROUTES } from '@/controllers/router/router.contants';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { LogoSquare } from '@/components/common/Logo';

interface Props {
  shouldAddStylesForMobile?: boolean;
  noMargin?: boolean;
  size?: number;
}

export const HeaderLogo: FC<Props> = memo((props) => {
  const { t } = useTranslation([I18N_CODES.platformSidebar]);
  const {
    shouldAddStylesForMobile = false,
    noMargin = false,
    size = 44,
  } = props;

  const isSubDomainUA = useDefaultDomain();

  const features = useFeatures();
  const shouldShowCurrentThingLogo = isSubDomainUA
    && features.isEnabled(Features.CurrentThingPlatformLogo);

  const [staticFiles] = useStaticFilesDeprecated();

  const logoFileKey = STATIC_IMAGES_DEPRECATED.platformLogoCurrentThing;
  const currentThingLogoUrl = staticFiles[logoFileKey]?.url;

  const activeRoute = useActiveRoute();

  const [isFreeTrialFunnel] = useLocalStorage(
    LOCAL_STORAGE_KEYS.isFreeTrialFunnel,
    false,
  );

  const href = isFreeTrialFunnel
    ? ROUTES.introOffer
    : activeRoute;

  return (
    <Link
      href={href}
      passHref
    >
      <a
        data-qa="website-link"
        title={t(`${I18N_CODES.platformSidebar}:sidebar.website_link`)}
        aria-label={t(`${I18N_CODES.platformSidebar}:sidebar.website_link`)}
        className={cn(styles.sidebarLogoLink, {
          [styles.sidebarLogoMobile]: shouldAddStylesForMobile,
          [styles.noMargin]: noMargin,
        })}
        style={{ '--logo-size': `${size}px` } as React.CSSProperties}
      >
        {
          shouldShowCurrentThingLogo && !!currentThingLogoUrl
            ? <CurrentThingPlatformLogo url={currentThingLogoUrl} size={size} />
            : (
              <LogoSquare
                className={styles.logoIcon}
                customProps={{
                  withHoverEffect: true,
                }}
              />
            )
        }
      </a>
    </Link>
  );
});
