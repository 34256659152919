import React, { type FC, useContext } from 'react';
import { type SearchableEntityType } from '@/controllers/graphql/generated';
import { emptyFunction } from '@/lib/helpers/functional';

interface GlobalSearchContextType {
  isSearchModalOpened: boolean;
  openModal: (initiallySelectedCategory?: SearchableEntityType) => void;
  closeModal: () => void;
  selectedCategory: SearchableEntityType | null;
  setSelectedCategory: (entity: SearchableEntityType | null) => void;
}

type GlobalSearchContextHook = () => GlobalSearchContextType;

const GlobalSearchContext = React.createContext<GlobalSearchContextType>({
  isSearchModalOpened: false,
  openModal: emptyFunction,
  closeModal: emptyFunction,
  selectedCategory: null,
  setSelectedCategory: emptyFunction,
});

export const GlobalSearchContextProvider: FC<
  GlobalSearchContextType
> = ({ children, ...value }) => (
  <GlobalSearchContext.Provider value={value}>
    {children}
  </GlobalSearchContext.Provider>
);

export const useGlobalSearchContext: GlobalSearchContextHook = () => (
  useContext(GlobalSearchContext)
);
