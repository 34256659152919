import {
  type FC,
  type JSX,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { MINIMAL_DESKTOP_WIDTH } from '@/constants/breakpoints';
import { useIsMinimalWidth } from '@/hooks/useIsMinimalWidth';
import { PopUpTypeMode } from '@/components/ui/Modal';
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import styles from '@/components/ui/PlatformResponsiveModal/PlatformResponsiveModal.module.scss';
import { PlatformSidebarHeight, PlatformSidebarMode } from '@/components/ui/PlatformSidebar';
import { useSwipeableHandlers } from '@/components/ui/PlatformResponsiveModal/useSwipeableHandlers';
import { cn } from '@/lib';

const PlatformSidebar = dynamic(
  () => import('@/components/ui/PlatformSidebar')
    .then((mod) => mod.PlatformSidebar),
  { ssr: true },
);
const Modal = dynamic(
  () => import('@/components/ui/Modal/Modal')
    .then((mod) => mod.Modal),
  { ssr: true },
);

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  renderTitle?: () => JSX.Element;
  renderTitleIcon?: () => JSX.Element;
  modalClassName?: string;
  modalType?: PopUpTypeMode;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  sidebarVhHeight?: PlatformSidebarHeight;
  shouldCloseOnSwipe?: boolean;
  sidebarClassName?: string;
  hasStickyTitle?: boolean;
  dataQa?: string;
  paddingless?: boolean;
  modalScrollAreaClassName?: string;
  titleClassNames?: string;
  renderCloseButton?: boolean;
  closeIconClassNames?: string;
  sidebarTitleClassNames?: string;
}

/* Render modal content in sidebar on tablet and mobile devices */
export const PlatformResponsiveModal: FC<Props> = memo(({
  isOpen,
  closeModal,
  renderTitle,
  renderTitleIcon,
  modalClassName,
  children,
  modalType = PopUpTypeMode.Default,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  sidebarVhHeight = PlatformSidebarHeight.Auto,
  shouldCloseOnSwipe = false,
  sidebarClassName,
  sidebarTitleClassNames,
  dataQa,
  paddingless,
  modalScrollAreaClassName,
  titleClassNames,
  renderCloseButton,
  closeIconClassNames,
}) => {
  const isSmallScreen = useIsMinimalWidth(MINIMAL_DESKTOP_WIDTH);
  const {
    setActive: setScrollLock,
  } = useBodyScrollLock(false);
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  const mode = PlatformSidebarMode.Bottom;

  useEffect(() => {
    if (isOpen) {
      setScrollLock(true);
    }

    return () => {
      setScrollLock(false);
    };
  }, [isOpen, setScrollLock]);

  const closeModalWithScrollRelease = useCallback(
    () => {
      closeModal();
      setScrollLock(false);
    },
    [closeModal, setScrollLock],
  );

  const {
    handlers,
    shouldTriggerSidebarClose,
    offsetStyle,
  } = useSwipeableHandlers({
    shouldCloseOnSwipe,
    isBottomSidebar: true,
    setIsScrollLocked,
    isOpened: isOpen,
    mode,
  });

  if (isSmallScreen) {
    return (
      <PlatformSidebar
        dataQa={dataQa || 'mobile-modal'}
        isSidebarOpen={isOpen}
        closeSidebar={closeModalWithScrollRelease}
        mode={PlatformSidebarMode.Bottom}
        sidebarVhHeight={sidebarVhHeight}
        shouldInnerScrollBeLocked={isScrollLocked}
        shouldTriggerSidebarClose={shouldTriggerSidebarClose}
        offsetStyle={offsetStyle}
        sidebarClassName={sidebarClassName}
      >
        <div
          {...handlers}
          ref={(element) => {
            handlers.ref(element);
          }}
          className={styles.handleWrapper}
        >
          <div className={styles.handle} />
        </div>

        <div className={cn(styles.sidebarTitle, sidebarTitleClassNames)}>
          {renderTitleIcon?.()}

          {renderTitle?.()}
        </div>

        {children}
      </PlatformSidebar>
    );
  }

  return (
    <Modal
      dataQa={dataQa}
      isOpen={isOpen}
      className={modalClassName}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={closeModalWithScrollRelease}
      type={modalType}
      renderTitle={renderTitle}
      renderTitleIcon={renderTitleIcon}
      paddingless={paddingless}
      scrollAreaClassName={modalScrollAreaClassName}
      titleClassNames={titleClassNames}
      renderCloseButton={renderCloseButton}
      closeIconClassName={closeIconClassNames}
    >
      {children}
    </Modal>
  );
});
