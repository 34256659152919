import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { type GetLeaderboardDataByUserIdQuery } from '@/components/platform/Learn/graphql/generated/getLeaderboardDataByUserId.query.generated';
import { type GetLatestUserAchievementsQuery } from '@/components/platform/Learn/CoursePageContent/graphql/generated/getLatestUserAchievements.query.generated';
import { type ModuleStatus } from '@/components/platform/Learn/CoursePage/constants';
import { type LearnCourseModuleWithDailyTasksFragment } from '@/controllers/courses/generated/LearnCourseModuleWithDailyTasks.fragment.generated';
import { emptyFunction } from '@/lib/helpers/functional';
import { type GetAverageTypingSpeedByUserIdQuery } from '@/components/type/graphql/generated/getAverageTypingSpeedByUserId.query.generated';

interface LeaderboardUserTypes {
  isFullTimeUser: boolean;
  isPartTimeUser: boolean;
  isLeadUser: boolean;
}

export type CourseModuleToContinueLearning =
  LearnCourseModuleWithDailyTasksFragment & {
  moduleStatus: ModuleStatus;
};

interface CoursePageContentContextType {
  leaderboardData: GetLeaderboardDataByUserIdQuery | null;
  setLeaderboardData: Dispatch<SetStateAction<
  GetLeaderboardDataByUserIdQuery | null
  >>;
  leaderboardUserTypes: LeaderboardUserTypes | null;
  setLeaderboardUserTypes: Dispatch<SetStateAction<
    LeaderboardUserTypes | null
  >>;
  hasDailyTasksLinks: boolean;
  setHasDailyTasksLinks: Dispatch<SetStateAction<boolean>>;
  achievementsToDisplay: GetLatestUserAchievementsQuery | null;
  setAchievementsToDisplay: Dispatch<SetStateAction<
  GetLatestUserAchievementsQuery | null
  >>;
  averageTypingSpeedData: GetAverageTypingSpeedByUserIdQuery | null;
  setAverageTypingSpeedData: Dispatch<SetStateAction<
    GetAverageTypingSpeedByUserIdQuery | null
  >>;
  preservedActiveModule: string | null;
  preserveActiveModule: (value: string) => void;
  activeStudyModuleSlug: string | null;
  setActiveStudyModuleSlug: Dispatch<SetStateAction<string | null>>;
  moduleToContinueLearning: CourseModuleToContinueLearning | null;
  setModuleToContinueLearning: Dispatch<SetStateAction<
    CourseModuleToContinueLearning | null
  >>;
}

const CoursePageContentContext = createContext<CoursePageContentContextType>({
  leaderboardData: null,
  setLeaderboardData: emptyFunction,
  leaderboardUserTypes: null,
  setLeaderboardUserTypes: emptyFunction,
  hasDailyTasksLinks: false,
  setHasDailyTasksLinks: emptyFunction,
  achievementsToDisplay: null,
  setAchievementsToDisplay: emptyFunction,
  averageTypingSpeedData: null,
  setAverageTypingSpeedData: emptyFunction,
  preservedActiveModule: null,
  preserveActiveModule: emptyFunction,
  activeStudyModuleSlug: null,
  setActiveStudyModuleSlug: emptyFunction,
  moduleToContinueLearning: null,
  setModuleToContinueLearning: emptyFunction,
});

export const CoursePageContentContextProvider: FC = ({ children }) => {
  const [leaderboardData, setLeaderboardData] = useState<
    GetLeaderboardDataByUserIdQuery | null
  >(null);
  const [hasDailyTasksLinks, setHasDailyTasksLinks] = useState(false);
  const [achievementsToDisplay, setAchievementsToDisplay] = useState<
    GetLatestUserAchievementsQuery | null
  >(null);
  const [averageTypingSpeedData, setAverageTypingSpeedData] = useState<
    GetAverageTypingSpeedByUserIdQuery | null
  >(null);
  const [leaderboardUserTypes, setLeaderboardUserTypes] = useState<
    LeaderboardUserTypes | null
  >(null);
  const [activeStudyModuleSlug, setActiveStudyModuleSlug] = useState<
    string | null
  >(null);

  const [preservedActiveModule, preserveActiveModule] = useLocalStorage<
    string | null
  >(LOCAL_STORAGE_KEYS.activeModule(''), '');
  const [moduleToContinueLearning, setModuleToContinueLearning] = useState<
  CourseModuleToContinueLearning | null
  >(null);

  return (
    <CoursePageContentContext.Provider value={{
      leaderboardData,
      setLeaderboardData,
      leaderboardUserTypes,
      setLeaderboardUserTypes,
      hasDailyTasksLinks,
      setHasDailyTasksLinks,
      achievementsToDisplay,
      setAchievementsToDisplay,
      averageTypingSpeedData,
      setAverageTypingSpeedData,
      preservedActiveModule,
      preserveActiveModule,
      activeStudyModuleSlug,
      setActiveStudyModuleSlug,
      moduleToContinueLearning,
      setModuleToContinueLearning,
    }}
    >
      {children}
    </CoursePageContentContext.Provider>
  );
};

export const useCoursePageContentContext = () => useContext(
  CoursePageContentContext,
);
