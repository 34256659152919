import React, { type FC, memo } from 'react';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { Modal, VerticalPositionMode } from '@/components/ui/Modal';
import { GlobalSearch } from '@/components/platform/GlobalSearch/components/GlobalSearch';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useKeyboardShortcut } from '@/hooks/useKeyboardShortcut';
import { KeyboardKey } from '@/constants/keyboard';
import { OPEN_MODAL_KEYBOARD_SHORTCUT_KEY } from '@/components/platform/GlobalSearch/constants';
import { useRouteChangeCallback } from '@/controllers/router/router.hooks/useRouteChangeCallback';

export const GlobalSearchModal: FC = memo(() => {
  const features = useFeatures();

  const isGlobalSearchEnabled = features.isEnabled(
    Features.ChatEntitiesInGlobalSearch,
  );

  const {
    isSearchModalOpened,
    openModal,
    closeModal,
  } = useGlobalSearchContext();

  useRouteChangeCallback(closeModal);

  useKeyboardShortcut({
    invokeFunction: openModal,
    shortcutKey: OPEN_MODAL_KEYBOARD_SHORTCUT_KEY,
    desiredFirstKeys: [KeyboardKey.Meta, KeyboardKey.Ctrl],
  });

  if (!isGlobalSearchEnabled) {
    return null;
  }

  return (
    <Modal
      isOpen={isSearchModalOpened}
      onRequestClose={closeModal}
      verticalPositioning={VerticalPositionMode.Top}
      overflowVisible
      paddingless
    >
      <GlobalSearch />
    </Modal>
  );
});
