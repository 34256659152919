import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconStudentReview: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.39924 11.3999C6.80756 11.3999 7.8 10.6307 7.8 9.37106C7.8 7.99804 6.68791 7.64132 5.64009 7.30521L5.63554 7.30375C4.63365 6.99606 4.37845 6.7749 4.37845 6.36144C4.37845 5.97683 4.69981 5.68836 5.24802 5.68836C5.91909 5.68836 6.27826 6.04413 6.51456 6.55375L7.61096 5.90952C7.16673 4.96721 6.33497 4.3999 5.24802 4.3999C4.1138 4.3999 3.0741 5.13067 3.0741 6.3999C3.0741 7.67875 4.05709 8.11144 5.06843 8.40952C6.05142 8.69798 6.49565 8.89029 6.49565 9.39029C6.49565 9.76529 6.22155 10.1018 5.43705 10.1018C4.61474 10.1018 4.15161 9.69798 3.91531 9.07298L2.8 9.73644C3.15917 10.7364 4.04764 11.3999 5.39924 11.3999ZM13.2 11.3999H11.8023L10.4795 8.9699H9.49456V11.3999H8.2V4.3999H10.8266C12.0368 4.3999 13.0124 5.4399 13.0124 6.7199C13.0124 7.5999 12.4964 8.3899 11.746 8.7599L13.2 11.3999ZM10.8266 5.6899H9.49456V7.7599H10.8266C11.3144 7.7599 11.7178 7.2999 11.7178 6.7199C11.7178 6.1399 11.3144 5.6899 10.8266 5.6899Z" fill="currentColor" />
  </BaseIcon>
);
