import {
  type SetStateAction,
  useCallback,
  useState,
  type Dispatch,
  type CSSProperties,
} from 'react';
import {
  useSwipeable,
  DOWN,
  RIGHT,
  type SwipeableHandlers,
} from 'react-swipeable';
import {
  MAX_DRAGGING_OFFSET_HORIZONTAL,
  MAX_DRAGGING_OFFSET_VERTICAL,
  TRANSITION_DURATION,
} from '@/components/ui/PlatformSidebar/constants';
import { PlatformSidebarMode } from '@/components/ui/PlatformSidebar';

interface UseSwipableHandlers {
  ({
    shouldCloseOnSwipe,
    isBottomSidebar,
    setIsScrollLocked,
    mode,
    isOpened,
  }: {
    shouldCloseOnSwipe: boolean;
    isBottomSidebar: boolean;
    setIsScrollLocked?: Dispatch<SetStateAction<boolean>>;
    mode?: PlatformSidebarMode;
    isOpened?: boolean;
  }): {
    handlers: SwipeableHandlers;
    swipeOffset: number;
    offsetStyle: CSSProperties;
    shouldTriggerSidebarClose: boolean;
  };
}

export const useSwipeableHandlers: UseSwipableHandlers = ({
  shouldCloseOnSwipe,
  isBottomSidebar,
  setIsScrollLocked,
  mode,
  isOpened,
}) => {
  const [swipeOffset, setSwipeOffset] = useState(0);
  const [
    shouldTriggerSidebarClose,
    setShouldTriggerSidebarClose,
  ] = useState(false);

  const resetOffset = useCallback((withDelay = false) => {
    if (withDelay) {
      setTimeout(() => {
        setSwipeOffset(0);
        setShouldTriggerSidebarClose(false);
      }, TRANSITION_DURATION);
    } else {
      setSwipeOffset(0);
      setShouldTriggerSidebarClose(false);
    }

    setIsScrollLocked?.(false);
  }, [setIsScrollLocked]);

  const handlers = useSwipeable(
    shouldCloseOnSwipe
      ? {
        onSwiping: ({
          dir,
          absX,
          absY,
          velocity,
        }) => {
          setIsScrollLocked?.(true);
          const velocityEffect = velocity * 30; // Adjust multiplier for desired sensitivity

          // Calculate offset with velocity
          const offsetWithVelocity = isBottomSidebar
            ? absY + velocityEffect
            : absX + velocityEffect;

          if (isBottomSidebar && dir === DOWN) {
            setSwipeOffset(Math.round(offsetWithVelocity));
          } else if (!isBottomSidebar && dir === RIGHT) {
            setSwipeOffset(Math.round(offsetWithVelocity));
          }
        },
        onSwipedUp: () => {
          resetOffset();
        },
        onSwipedDown: ({ absY }) => {
          if (absY > MAX_DRAGGING_OFFSET_VERTICAL) {
            setShouldTriggerSidebarClose(true);
            resetOffset(true);

            return;
          }

          resetOffset();
        },
        onSwipedRight: ({ absX }) => {
          if (absX > MAX_DRAGGING_OFFSET_HORIZONTAL) {
            setShouldTriggerSidebarClose(true);
            resetOffset(true);

            return;
          }

          resetOffset();
        },
      }
      : {},
  );

  let offsetStyle: CSSProperties = mode === PlatformSidebarMode.Bottom
    ? { transform: `translateY(${swipeOffset}px)` }
    : { transform: `translateX(${swipeOffset}px)` };

  if (
    !isOpened
    || swipeOffset === 0
    || !isBottomSidebar
  ) {
    offsetStyle = {};
  }

  return {
    handlers,
    swipeOffset,
    shouldTriggerSidebarClose,
    offsetStyle,
  };
};
