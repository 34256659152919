import React, { type FC } from 'react';
import { PlatformResponsiveModal } from '@/components/ui/PlatformResponsiveModal/PlatformResponsiveModal';
import { StripePayment } from '@/components/paymentProviders/Stripe/StripePayment';
import { type ProcessSubscriptionPaymentPricingOption, type ProcessSubscriptionPaymentSubscriptionPlan } from '@/components/platform/SubscriptionProduct/typedefs';
import { ContentPreloader } from '@/components/common/ContentPreloader';
import { Modal } from '@/components/ui/Modal';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from '@/components/platform/SubscriptionProduct/components/StripeCheckoutModal/StripeCheckoutModal.module.scss';
import { Button } from '@/components/ui/Button';
import { IconCross } from '@/components/ui/icons/IconCross';
import { PlatformSidebarHeight } from '@/components/ui/PlatformSidebar';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Error } from '@/components/error/Error';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

interface Props {
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  isOpen: boolean;
  closeModal: () => void;
  isLoading: boolean;
  clientSecret?: string | null;
  redirectUrl?: string | null;
}

export const StripeCheckoutModal: FC<Props> = ({
  subscriptionPlan,
  pricingOption,
  isOpen,
  closeModal,
  isLoading,
  clientSecret,
  redirectUrl,
}) => {
  const { t } = useTranslation(I18N_CODES.payment);

  const [authUser] = useAuthUser({ ssr: true });

  return (
    <PlatformResponsiveModal
      isOpen={isOpen}
      modalClassName={styles.modal}
      sidebarClassName={styles.sidebar}
      modalType={Modal.type.Paywall}
      sidebarVhHeight={PlatformSidebarHeight.Vh90}
      renderTitle={() => (
        <h3 className={cn(styles.modalTitle, typography.platformH2)} data-qa="stripe-checkout-title">
          {t(`${I18N_CODES.payment}:stripe_checkout.title`)}
        </h3>
      )}
      closeModal={closeModal}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      paddingless
    >
      <>
        {isLoading && (
          <ContentPreloader text={t(`${I18N_CODES.payment}:stripe_checkout.preloader`)} />
        )}

        {!isLoading && !clientSecret && (
          <Error code={400} />
        )}

        {!isLoading && clientSecret && (
          <>
            <div className={styles.sidebarTitle}>
              <h3 className={typography.platformH2}>
                {t(`${I18N_CODES.payment}:stripe_checkout.title`)}
              </h3>

              <Button
                onClick={closeModal}
                RightIcon={IconCross}
                mode={Button.mode.TransparentLight}
                className={styles.closeButton}
              />
            </div>

            <div className={styles.checkoutContainer}>
              <StripePayment
                pricingOption={pricingOption}
                clientSecret={clientSecret}
                redirectUrl={redirectUrl ?? ''}
                subscriptionPlan={subscriptionPlan}
                email={authUser?.email}
              />
            </div>
          </>
        )}
      </>
    </PlatformResponsiveModal>
  );
};
