import React, {
  type FC, createContext, useContext, useMemo,
} from 'react';
import { type OnMentionClick, useHandleMentionClick } from '@/components/platform/Chat/hooks/useHandleMentionClick';
import { emptyFunction } from '@/lib/helpers/functional';
import { type ParticipantBaseFragment } from '../graphql/generated/participantBase.fragment.generated';

interface ParticipantsContextProps {
  participant: ParticipantBaseFragment | null;
  participantsMap: Map<
    number,
    ParticipantBaseFragment
  >;
  loadParticipant: (id: number) => void;
  loading?: boolean;
}

interface ParticipantsContextType extends ParticipantsContextProps {
  onMentionClick: (
    isMention: boolean,
    participantId: string,
  ) => OnMentionClick;
}

type ParticipantsContextHook = () => ParticipantsContextType;

const ParticipantsContext = createContext<ParticipantsContextType>({
  participant: null,
  participantsMap: new Map(),
  loadParticipant: emptyFunction,
  onMentionClick: () => emptyFunction,
  loading: false,
});

export const ParticipantsContextProvider: FC<ParticipantsContextProps> = ({
  children,
  ...value
}) => {
  const {
    participant,
    participantsMap,
    loadParticipant,
    loading,
  } = value;

  const { onMentionClick } = useHandleMentionClick();

  const memoizedValue = useMemo(() => ({
    participant,
    participantsMap,
    loadParticipant,
    onMentionClick,
    loading,
  }), [
    participant,
    participantsMap,
    loading,
    loadParticipant,
    onMentionClick,
  ]);

  return (
    <ParticipantsContext.Provider value={memoizedValue}>
      {children}
    </ParticipantsContext.Provider>
  );
};

export const useParticipantsContext: ParticipantsContextHook = () => (
  useContext(ParticipantsContext)
);
