import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { TaskReviewStatus } from '@/controllers/graphql/generated';
import { getReviewNotificationIcon } from '../../helpers/getReviewNotificationIcon';
import styles from './ReviewNotificationIcon.module.scss';

interface Props {
  taskReviewStatus: TaskReviewStatus;
}

export const ReviewNotificationIcon: FC<Props> = ({
  taskReviewStatus,
}) => {
  const IconComponent = getReviewNotificationIcon(
    taskReviewStatus,
  );

  const isApprovedTask = (
    taskReviewStatus === TaskReviewStatus.Approved
    || taskReviewStatus === TaskReviewStatus.ApprovedPeer
    || taskReviewStatus === TaskReviewStatus.ApprovedAi
  );

  const isRejectedTask = (
    taskReviewStatus === TaskReviewStatus.Rejected
    || taskReviewStatus === TaskReviewStatus.RejectedPeer
    || taskReviewStatus === TaskReviewStatus.RejectedAi
  );

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.successBackground]: isApprovedTask,
        [styles.errorBackground]: isRejectedTask,
      })}
    >
      <IconComponent
        className={cn(styles.icon, {
          [styles.success]: isApprovedTask,
          [styles.error]: isRejectedTask,
        })}
      />
    </div>
  );
};
