import React, { type FC, memo, useContext } from 'react';
import { cn } from '@/lib/classNames';
import { type FCIcon, type SvgProps } from '@/components/ui/icons/typedefs';
import {
  IconStyling,
  LikeRatingContext,
} from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingContext';
import styles from './RatingIcon.module.scss';

interface Props extends SvgProps {
  Icon: FCIcon;
  isActive: boolean;
}

export const RatingIcon: FC<Props> = memo((props) => {
  const { Icon, isActive, ...rest } = props;

  const { iconStyling } = useContext(LikeRatingContext);

  return (
    <Icon
      {...rest}
      className={cn(
        styles.icon,
        { [styles.isActive]: isActive },
        { [styles.lightGray]: iconStyling === IconStyling.LightGray },
      )}
    />
  );
});
