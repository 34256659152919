import {
  AchievementIconLocked,
  LockedIconName,
} from '@/components/platform/Achievements';
import {
  AchievementRarity,
  AchievementType,
} from '@/controllers/graphql/generated';
import {
  type AchievementToDisplay,
  pickRandomLockedAchievements,
  MAX_VISIBLE_ACHIEVEMENTS_COUNT,
} from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock';
import { useCoursePageContentContext } from '@/components/platform/Learn/CoursePageContent/CoursePageContentContext';

export interface UseAchievementsToDisplayResult {
  userAchievementsToDisplay: AchievementToDisplay[];
  userHasAchievementsUnlocked: boolean;
}

export const useAchievementsToDisplay = (): UseAchievementsToDisplayResult => {
  const { achievementsToDisplay } = useCoursePageContentContext();

  let userAchievementsToDisplay: AchievementToDisplay[] = [
    ...(achievementsToDisplay?.getLatestUserAchievements || []),
  ];

  const userHasAchievementsUnlocked = userAchievementsToDisplay.length > 0;

  if (
    userHasAchievementsUnlocked
    && userAchievementsToDisplay.length < MAX_VISIBLE_ACHIEVEMENTS_COUNT
  ) {
    const achievementsCount = userAchievementsToDisplay.length;

    userAchievementsToDisplay.length = MAX_VISIBLE_ACHIEVEMENTS_COUNT;

    userAchievementsToDisplay
      .fill({
        achievement: {
          id: 0,
          name: LockedIconName.Unknown,
          type: AchievementType.Personal,
          rarity: AchievementRarity.Common,
        },
        customIcon: AchievementIconLocked[LockedIconName.Unknown],
        textTruncated: false,
      }, achievementsCount, MAX_VISIBLE_ACHIEVEMENTS_COUNT);
  }

  if (!userHasAchievementsUnlocked) {
    userAchievementsToDisplay = pickRandomLockedAchievements(
      MAX_VISIBLE_ACHIEVEMENTS_COUNT,
    );
  }

  return {
    userAchievementsToDisplay,
    userHasAchievementsUnlocked,
  };
};
