import { type ApolloClient } from '@apollo/client';
import { type RateEntityMutation } from '@/controllers/graphql/generated/rateEntity.mutation.generated';
import { RatingNotificationFragmentDoc } from '@/components/platform/Notifications/graphql/generated/RatingNotification.fragment.generated';

export const updateRatingNotificationCache = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  data: RateEntityMutation,
  cacheEntityId: number,
) => {
  const identifier = client.cache.identify({
    __typename: 'RatingNotification',
    id: cacheEntityId,
  });

  client.cache.writeFragment({
    id: identifier,
    fragment: RatingNotificationFragmentDoc,
    fragmentName: 'RatingNotification',
    data: {
      userRating: data.upsertRating.rating,
    },
  });
};
