import React, { type FC, type PropsWithChildren } from 'react';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

type Props = PropsWithChildren<{
  tooltipText: string | undefined;
}>;

export const ButtonTooltipWrapper: FC<Props> = ({ children, tooltipText }) => {
  const { t } = useTranslation([I18N_CODES.common]);

  if (!tooltipText) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <ToolTip
      text={t(`${I18N_CODES.common}:${tooltipText}`)}
      widthMode={ToolTipWidthMode.Full}
    >
      {children}
    </ToolTip>
  );
};
