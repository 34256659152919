import React, { memo, useContext } from 'react';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { LikeRatingContext } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingContext';
import { RatingIcon } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/RatingIcon';
import { IconDislike } from '@/components/ui/icons/IconDislike';

export const DislikeIcon: FCIcon = memo((props) => {
  const { rating } = useContext(LikeRatingContext);
  const isActive = !!rating && rating < 0;

  return (
    <RatingIcon
      {...props}
      Icon={IconDislike}
      isActive={isActive}
    />
  );
});
