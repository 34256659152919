import { type FormFields } from '@/components/ui/ChatInput/chatInput.typedefs';
import { disableDefaultMarkdownListBehavior } from '@/components/platform/Chat/chat.helpers';

export const getParsedFields = (fields: FormFields) => ({
  ...fields,
  text: fields.text || '',
});

export const processRTETextToChatMarkdown = (text: string): string => {
  const lines = disableDefaultMarkdownListBehavior(text).split('\n');

  const blockquoteLineStart = '>';

  const processedLines: string[] = [];

  let isLastLineBlockquote = false;
  let consecutiveEmptyLinesCount = 0;

  lines.forEach((line) => {
    if (line.startsWith(blockquoteLineStart)) {
      if (line.length > 1) {
        // Add non-empty blockquote line as it is
        // but set isLastLineBlockquote to true
        // to add two empty lines after the end of blockquote
        isLastLineBlockquote = true;
        processedLines.push(line);
      }

      consecutiveEmptyLinesCount = 0;

      return;
    }

    if (line.length !== 0) {
      // Add non-empty non-blockquote line as it is
      isLastLineBlockquote = false;
      processedLines.push(line);
      consecutiveEmptyLinesCount = 0;

      return;
    }

    consecutiveEmptyLinesCount += 1;

    if (isLastLineBlockquote) {
      // Add two empty lines after blockquote
      // to separate it from the next text block
      processedLines.push('');
      processedLines.push('');
      isLastLineBlockquote = false;

      return;
    }

    if (consecutiveEmptyLinesCount > 1) {
      // Add one empty line instead of two consecutive empty lines
      // to separate text blocks
      processedLines.push('');
    }
  });

  return processedLines.join('\n');
};
