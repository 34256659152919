import {
  type FC,
  type MouseEventHandler,
  type ReactNode,
  memo,
  useState,
} from 'react';
import { v4 as uuid4 } from 'uuid';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonName?: string;
  children: ReactNode;
  className?: string;
  dataQa?: string;
}

export const OnClick: FC<Props> = memo(({
  onClick,
  buttonName,
  children,
  className,
  dataQa,
}) => {
  const [id] = useState(uuid4);

  return onClick
    ? (
      <label htmlFor={id} className={className} data-qa={dataQa}>
        {children}

        <button
          style={{ display: 'none' }}
          onClick={onClick}
          id={id}
        >
          {buttonName}
        </button>
      </label>
    )
    : <>{children}</>;
});
