import { useCallback, useEffect } from 'react';
import { type TeacherWorkingTimesResult, type WorkingTime } from '@/controllers/graphql/generated';
import { GetLastWorkingTimeDocument, type GetLastWorkingTimeQuery, useGetLastWorkingTimeQuery } from '@/components/platform/TimeTracker/graphql/generated/getLastWorkingTime.query.generated';
import { TIMETRACKER_CHANNEL_NAME } from '@/components/platform/TimeTracker/TimeTracker.constants';
import { type BroadcastMessage, TimeTrackerMessageType } from '@/components/platform/TimeTracker/TimeTracker.typedefs';
import {
  GetCurrentWorkingTimeDocument,
  type GetCurrentWorkingTimeQuery,
  useGetCurrentWorkingTimeQuery,
} from '@/components/platform/ContractorsManagement/graphql/generated/getCurrentWorkingTime.query.generated';
import {
  GetTeacherWorkingTimesDocument,
  type GetTeacherWorkingTimesQuery,
  useGetTeacherWorkingTimesQuery,
} from '@/components/platform/ContractorsManagement/graphql/generated/getTeacherWorkingTimes.query.generated';

type UseUpdateWorkingTimeCacheResult = {
  updateCurrentWorkingTimeCache: (data?: WorkingTime | null) => void;
  updateLastWorkingTimeCache: (data?: WorkingTime | null) => void;
  updateTeacherWorkingTimesCache: (data?: TeacherWorkingTimesResult) => void;
  handleRefetchTime: () => Promise<void>;
};

export const useUpdateWorkingTimeCache = ()
: UseUpdateWorkingTimeCacheResult => {
  const {
    refetch: refetchTeacherWorkingTimes,
    client: teacherWorkingTimeClient,
  } = useGetTeacherWorkingTimesQuery();

  const {
    client: currentWorkingTimeClient,
  } = useGetCurrentWorkingTimeQuery();

  const {
    refetch: refetchLastWorkingTime,
    client: lastWorkingTimeClient,
  } = useGetLastWorkingTimeQuery();

  const updateCurrentWorkingTimeCache = useCallback(
    (data?: WorkingTime | null) => {
      currentWorkingTimeClient.cache.writeQuery<
        GetCurrentWorkingTimeQuery
      >({
        query: GetCurrentWorkingTimeDocument,
        data: {
          getCurrentWorkingTime: data,
        },
      });
    },
    [currentWorkingTimeClient.cache],
  );

  const updateLastWorkingTimeCache = useCallback(
    (data?: WorkingTime | null) => {
      lastWorkingTimeClient.cache.writeQuery<
        GetLastWorkingTimeQuery
      >({
        query: GetLastWorkingTimeDocument,
        data: {
          getLastWorkingTime: data,
        },
      });
    },
    [lastWorkingTimeClient.cache],
  );

  const updateTeacherWorkingTimesCache = useCallback(
    (data?: TeacherWorkingTimesResult) => {
      teacherWorkingTimeClient.cache.writeQuery<
        GetTeacherWorkingTimesQuery
      >({
        query: GetTeacherWorkingTimesDocument,
        data: {
          getTeacherWorkingTimes: data,
        },
      });
    },
    [teacherWorkingTimeClient.cache],
  );

  useEffect(() => {
    if (typeof BroadcastChannel === 'undefined') {
      return;
    }

    const channel = new BroadcastChannel(TIMETRACKER_CHANNEL_NAME);

    channel.onmessage = (event: MessageEvent<BroadcastMessage>) => {
      switch (event.data.type) {
        case TimeTrackerMessageType.CurrentWorkingTimeSync:
          updateCurrentWorkingTimeCache(event.data.currentWorkingTime);
          break;

        case TimeTrackerMessageType.LastWorkingTimeSync:
          updateLastWorkingTimeCache(event.data.lastWorkingTime);
          break;

        case TimeTrackerMessageType.TeacherWorkingTimesSync:
          updateTeacherWorkingTimesCache(event.data.teacherWorkingTimes);
          break;

        default:
          break;
      }
    };

    // eslint-disable-next-line consistent-return
    return () => {
      channel.close();
    };
  }, [
    updateCurrentWorkingTimeCache,
    updateLastWorkingTimeCache,
    updateTeacherWorkingTimesCache,
  ]);

  const handleRefetchTime = useCallback(async () => {
    Promise.all([
      refetchTeacherWorkingTimes(),
      refetchLastWorkingTime(),
    ])
      .then(([
        teacherWorkingTimesRefetched,
        lastWorkingTimeRefetched,
      ]) => {
        if (typeof BroadcastChannel === 'undefined') {
          return;
        }

        const channel = new BroadcastChannel(TIMETRACKER_CHANNEL_NAME);

        channel.postMessage({
          type: 'currentWorkingTimeSync',
          currentWorkingTime: null,
        });

        channel.postMessage({
          type: 'teacherWorkingTimesSync',
          teacherWorkingTimes: (
            teacherWorkingTimesRefetched.data?.getTeacherWorkingTimes
          ),
        });

        channel.postMessage({
          type: 'lastWorkingTimeSync',
          lastWorkingTime: (
            lastWorkingTimeRefetched.data?.getLastWorkingTime
          ),
        });

        channel.close();
      });
  }, [
    refetchTeacherWorkingTimes,
    refetchLastWorkingTime,
  ]);

  return {
    updateCurrentWorkingTimeCache,
    updateLastWorkingTimeCache,
    updateTeacherWorkingTimesCache,
    handleRefetchTime,
  };
};
