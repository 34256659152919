import { CourseUserStatus } from '@/controllers/graphql/generated';
import { newApplication, singleApplication } from '@/controllers/router/router.utils/applications';
import { SELECTED_ALL_COURSES_LEARN_TAB } from '@/controllers/learn/learn.constants';
import {
  type FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export const getApplicationLink = (
  options: {
    course?: FtCourseWithMinimalCourseApplicationInfoFragment;
    courseSlug?: string;
    source: string;
  },
) => {
  const {
    course,
    courseSlug,
    source,
  } = options;

  let applicationHref = newApplication.href;

  if (course
    && course.courseUser
    && course.courseUser?.status === CourseUserStatus.New) {
    applicationHref = singleApplication(
      course.courseUser.id,
    ).href;
  }

  const searchParams = new URLSearchParams();

  if (courseSlug && courseSlug !== SELECTED_ALL_COURSES_LEARN_TAB) {
    searchParams.append(
      'courseSlug',
      courseSlug,
    );
  }

  searchParams.append('source', source);

  return `${applicationHref}?${searchParams.toString()}`;
};

export const getApplicationRoute = () => (
  renderRoute(allRoutes.learn.application, {})
);
