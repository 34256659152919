import {
  type DetailedHTMLProps,
  type FC,
  type HTMLAttributes,
} from 'react';
import { cn } from '@/lib/classNames';
import styles from './Divider.module.scss';

export enum DividerReferrals {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

type Props = {
  referral?: DividerReferrals;
} & DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export const Divider: FC<Props> = (props) => {
  const {
    children,
    className,
    referral = DividerReferrals.Horizontal,
    ...elementProps
  } = props;

  if (referral === DividerReferrals.Vertical) {
    return (
      <span
        className={cn(
          className,
          styles.verticalDivider,
        )}
        {...elementProps}
      />
    );
  }

  return (
    <span
      className={cn(className, styles.divider)}
      {...elementProps}
    >
      {children}
    </span>
  );
};
