import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import styles from './SearchResultWrapper.module.scss';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const SearchResultWrapper: FC<Props> = ({
  onClick,
  disabled,
  children,
}) => (
  <button
    onClick={onClick}
    className={cn(styles.wrapper, { [styles.disabled]: !!disabled })}
    data-focusable
  >
    {children}
  </button>
);
