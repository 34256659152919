import { type FC } from 'react';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  AchievementItem,
} from '@/components/platform/Achievements';
import { FireworkBackground } from '@/components/ui/FireworkBackground';
import { type UserAchievementFullFragment } from '@/modules/achievements/communication/generated/userAchievementFull.fragment.generated';
import styles from './AchievementGamificationModalContent.module.scss';

export type AchievementModalPayload = Omit<UserAchievementFullFragment, 'userId'>;

type Props = {
  onClose: () => void;
  userAchievement: AchievementModalPayload;
};

export const AchievementGamificationModalContent: FC<Props> = ({
  onClose,
  userAchievement,
}) => {
  const { t } = useTranslation([I18N_CODES.common, I18N_CODES.achievements]);

  const { achievement } = userAchievement;

  return (
    <div className={styles.container}>
      <FireworkBackground className={styles.container}>
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            <AchievementItem
              achievement={achievement}
              userAchievement={userAchievement}
              iconWidth={88}
              iconHeight={88}
              showToolTip={false}
              showNewBadge={false}
              textTruncated={false}
              withShadow
            />
          </div>

          <div className={styles.textContainer}>
            <h1 className={styles.heading}>
              {t(`${I18N_CODES.common}:achievement_unlocked_description`)}
            </h1>

            <p className={styles.description} data-qa={`${achievement.name}-description`}>
              {t(`${I18N_CODES.achievements}:${achievement.name}_description`)}
            </p>
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            onClick={onClose}
            mode={ButtonMode.Primary}
            size={ButtonSize.Large}
            hasFullWidth
            text={t(`${I18N_CODES.achievements}:achievement_action.continue`)}
            dataQa='achievement-modal-continue-button'
          />
        </div>
      </FireworkBackground>
    </div>
  );
};
