import React, { type FC } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { type AbstractNotificationFragment } from '@/components/platform/Notifications/graphql/generated/AbstractNotification.fragment.generated';
import { UserNotificationsStatuses, UserNotificationsTypes } from '@/controllers/graphql/generated';
import { BasicNotification } from '../BasicNotification';
import { ReviewNotificationIcon } from '../ReviewNotificationIcon';

interface Props {
  notification: AbstractNotificationFragment;
}

export const NotificationsItem: FC<Props> = ({
  notification,
}) => {
  const { t } = useTranslation([I18N_CODES.platformNotifications]);

  switch (notification.__typename) {
    case UserNotificationsTypes.TaskReviewNotification:
      return (
        <BasicNotification
          notificationId={notification.id}
          sender={notification.sender}
          message={notification.message}
          createdAt={notification.createdAt}
          actionText={t(`${I18N_CODES.platformNotifications}:review_notification_action_button`)}
          renderIcon={() => (
            <ReviewNotificationIcon
              taskReviewStatus={notification.taskReviewStatus}
            />
          )}
          actionLink={notification.actionLink}
          isUnread={notification.status === UserNotificationsStatuses.Unread}
        />
      );

    case UserNotificationsTypes.RatingNotification:
      return (
        <BasicNotification
          notificationId={notification.id}
          sender={notification.sender}
          message={notification.message}
          createdAt={notification.createdAt}
          ratingData={{
            relatedId: notification.relatedId,
            relatedType: notification.relatedType,
            userRating: notification.userRating,
          }}
          isUnread={notification.status === UserNotificationsStatuses.Unread}
        />
      );

    case UserNotificationsTypes.BasicNotification:
      return (
        <BasicNotification
          notificationId={notification.id}
          sender={notification.sender}
          message={notification.message}
          createdAt={notification.createdAt}
          actionLink={notification.actionLink}
          actionText={notification.actionButtonCode
            ? t(`${I18N_CODES.platformNotifications}:${notification.actionButtonCode}`)
            : t(`${I18N_CODES.platformNotifications}:notification_action_button_text`)}
          customImageType={notification.imageType || undefined}
          isUnread={notification.status === UserNotificationsStatuses.Unread}
        />
      );

    default:
      return (
        <BasicNotification
          notificationId={notification.id}
          sender={notification.sender}
          message={notification.message}
          createdAt={notification.createdAt}
          actionLink={notification.actionLink}
          isUnread={notification.status === UserNotificationsStatuses.Unread}
        />
      );
  }
};
