import { CourseType } from '@/controllers/graphql/generated';
import { type AuthUserFragment } from '../graphql/generated/AuthUser.fragment.generated';

export const isFulltimeStudent = (
  user: Pick<AuthUserFragment, 'courseType' | 'studentsGroup' | 'studentStatus'> | null,
): boolean => (
  user?.courseType === CourseType.Postpaid
    && !!(user?.studentsGroup)
    && !!(user?.studentStatus)
);
