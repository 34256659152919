import { type FC } from 'react';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Currencies } from '@/controllers/graphql/generated';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import { type ProcessSubscriptionPaymentPricingOption, type ProcessSubscriptionPaymentSubscriptionPlan } from '@/components/platform/SubscriptionProduct/typedefs';
import styles from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/CheckoutForm/StripeCheckoutForm.module.scss';
import { cn } from '@/lib';
import { StripeCheckoutTermsAndConditions } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/TermsAndConditions/StripeCheckoutTermsAndConditions';
import { typography } from '@/components/ui/typography';
import {
  checkIfPlanIsFreeTrial,
} from '@/controllers/subscriptionPlans/subscriptionPlans.helpers/checkIfPlanIsFreeTrial';

interface Props {
  submit: (e: any) => Promise<void>;
  isLoading: boolean;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  renderedInPortal?: boolean;
  buttonText?: string;
}

export const SubmitSection: FC<Props> = ({
  submit,
  isLoading,
  subscriptionPlan,
  pricingOption,
  renderedInPortal = false,
  buttonText,
}) => {
  const {
    currency,
  } = subscriptionPlan;
  const { t } = useTranslation([I18N_CODES.payment]);

  const planPrice = subscriptionPlan.trialActive && subscriptionPlan.trialPrice
    ? subscriptionPlan.trialPrice
    : subscriptionPlan.salePrice;

  const totalPrice = pricingOption?.salePrice ?? planPrice;

  const formatter = useNumberFormatter({
    style: 'currency',
    currency: currency?.code ?? Currencies.Usd,
  });

  const isFreeTrialPlan = checkIfPlanIsFreeTrial({
    subscriptionPlan,
    pricingOption,
  });

  return (
    <>
      <div className={cn(styles.submitSection, {
        [styles.submitSectionPortal]: renderedInPortal,
      })}
      >
        {isFreeTrialPlan && (
          <div className={cn(styles.total, typography.landingH5)}>
            <span>
              {t(`${I18N_CODES.payment}:free_trial_pricing.payment_total_title`)}
            </span>

            <span>
              {t(`${I18N_CODES.payment}:free_trial_pricing.payment_total_sum`)}
            </span>
          </div>
        )}

        <Button
          {
          ...(
            renderedInPortal
              ? {
                onClick: submit,
              }
              : {
                type: 'submit',
              }
          )}
          text={buttonText ?? t(`${I18N_CODES.payment}:stripe.pay_button`, { amount: formatter.format(totalPrice) })}
          size={Button.size.Large}
          className={styles.submitButton}
          isLoading={isLoading}
          mode={Button.mode.BrandPrimary}
        />
      </div>

      {isFreeTrialPlan && (
        <div className={cn(typography.platformH4, styles.cancelAnytime)}>
          {t(`${I18N_CODES.payment}:free_trial_pricing.payment_cancel_anytime_tilte`)}
        </div>
      )}

      <StripeCheckoutTermsAndConditions
        subscriptionPlan={subscriptionPlan}
        pricingOption={pricingOption}
      />
    </>
  );
};
