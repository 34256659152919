import {
  type Dispatch,
  type FC,
  type SetStateAction,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  PaymentElement,
  CardElement,
} from '@stripe/react-stripe-js';
import { useForm } from 'react-final-form';
import { cn } from '@/lib/classNames';
import styles from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/CheckoutForm/StripeCheckoutForm.module.scss';
import { InputEmail } from '@/components/ui/FormElements/FormInputs/InputEmail';
import { FormField } from '@/components/ui/FormElements/FormField';
import { type StripeChekoutFormFields } from '@/components/paymentProviders/Stripe/typedefs';
import { PAYMENT_ELEMENT_OPTIONS } from '@/components/paymentProviders/Stripe/constants';
import { useTranslation, useRouter } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  type ProcessSubscriptionPaymentPricingOption,
  type ProcessSubscriptionPaymentSubscriptionPlan,
} from '@/components/platform/SubscriptionProduct/typedefs';
import { useStripeCheckout } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useStripeCheckout';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { composeValidators } from '@/controllers/forms/forms.helpers';
import { isEmail, isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { Portal } from '@/components/ui/Portal';
import { SubmitSection } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/CheckoutForm/SubmitSection';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { ROUTES } from '@/controllers/router/router.contants';
import { analyticsSDK } from '@/controllers/analytics';
import { PaymentMethods } from '@/controllers/analytics/generated';
import { typography } from '@/components/ui/typography';

interface Props {
  redirectUrl: string;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  submitPortalRef?: RefObject<HTMLDivElement> | null;
  clientSecret: string;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  email?: string;
  submitButtonText?: string;
  withCardElement?: boolean;
}

export const StripeCheckoutForm: FC<Props> = ({
  redirectUrl,
  subscriptionPlan,
  pricingOption,
  setErrorMessage,
  email,
  clientSecret,
  submitButtonText,
  withCardElement = false,
  submitPortalRef,
}) => {
  const { isPaymentDisabled } = useRouterQuery<{
    isPaymentDisabled: boolean;
  }>();

  const { t } = useTranslation(I18N_CODES.payment);

  const form = useForm<StripeChekoutFormFields>();

  const router = useRouter();

  const {
    submit,
    isLoading,
    setIsLoading,
    elements,
    isFormCompleted,
    validationError,
  } = useStripeCheckout({
    redirectUrl,
    clientSecret,
    setErrorMessage,
    subscriptionPlan,
    pricingOption,
  });

  const shouldSubmitBeRenderedInPortal = !!submitPortalRef;
  const trialDuration = (
    pricingOption?.trialPeriodDuration ?? subscriptionPlan.trialPeriodDuration
  );

  useEffect(() => {
    const formValues = form.getState().values;

    if (!formValues.email && email) {
      form.change('email', email);
    }
  }, [email, form]);

  const submitMock = useCallback(async (e: any) => {
    e.preventDefault();

    await analyticsSDK.subscriptionProduct.sendCheckoutPayClickedEvent({
      paymentMethod: PaymentMethods.Card,
    });

    elements?.submit();
    form.submit();

    if (!isFormCompleted) {
      return;
    }

    await router.push(ROUTES.user.paymentError);

    setIsLoading(true);
  }, [setIsLoading, form, isFormCompleted, elements, router]);

  const onSubmit = useMemo(
    () => (
      isPaymentDisabled
        ? submitMock
        : submit
    ),
    [submit, submitMock, isPaymentDisabled],
  );

  const cardElementOptions = useMemo(() => ({
    classes: {
      base: styles.cardElement,
      invalid: styles.cardElementInvalid,
      empty: cn({
        [styles.cardElementInvalid]: !!validationError,
      }),
    },
    hidePostalCode: true,
  }), [validationError]);

  return (
    <form
      onSubmit={onSubmit}
      method='post'
      className={styles.checkout}
    >
      <div className={styles.paymentElement}>
        {!email && (
          <div>
            <p className={cn(styles.emailLabel, 'mb-4')}>
              {t(`${I18N_CODES.payment}:stripe.email_field.label`)}
            </p>
            <FormField
              name="email"
              className={cn(styles.emailField, 'mb-12')}
              renderInput={(inputProps) => (
                <InputEmail
                  {...inputProps}
                  className="mb-4"
                  data-qa='stripe-email-field-placeholder'
                  placeholder={email ?? t(`${I18N_CODES.payment}:stripe.email_field.placeholder`)}
                  config={{
                    validate: composeValidators(
                      makeValidator(isNotEmpty, 'stripe.email_field'),
                      makeValidator(isEmail, 'validate_email'),
                    ),
                  }}
                />
              )}
            />
          </div>
        )}

        {withCardElement
          ? (
            <>
              <CardElement options={cardElementOptions} />

              {validationError && (
                <div
                  className={cn(
                    styles.cardInputValidationError,
                    typography.platformTextSmall,
                  )}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`${I18N_CODES.payment}:stripe_error.${validationError.code}`, {
                        defaultValue: validationError.message,
                      }),
                    }}
                  />
                </div>
              )}
            </>
          )
          : (
            <PaymentElement
              options={PAYMENT_ELEMENT_OPTIONS}
            />
          )}

      </div>
      {
        shouldSubmitBeRenderedInPortal && (
          <Portal portalNode={submitPortalRef.current ?? undefined}>
            <SubmitSection
              submit={submit}
              isLoading={isLoading}
              subscriptionPlan={subscriptionPlan}
              pricingOption={pricingOption}
              buttonText={t(`${I18N_CODES.payment}:stripe.start_trial_button`, {
                count: trialDuration ?? 1,
              })}
              renderedInPortal
            />
          </Portal>
        )
      }

      {
        !shouldSubmitBeRenderedInPortal && (
          <SubmitSection
            submit={submit}
            isLoading={isLoading}
            subscriptionPlan={subscriptionPlan}
            pricingOption={pricingOption}
            buttonText={submitButtonText}
          />
        )
      }
    </form>
  );
};
