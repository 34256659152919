import {
  type Dispatch,
  type InputHTMLAttributes,
  type SetStateAction,
  type ChangeEvent,
  memo,
  useMemo,
  useCallback,
} from 'react';
import InputMask from 'react-input-mask';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { leadZero } from '@/lib/leadZero';
import { type MinimumArray } from '@/lib/helpers/utility-types';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  setNewDuration?: Dispatch<SetStateAction<number>>;
  twoCharactersInHours?: boolean;
};

export const convertValueToDuration = (
  value: number,
  hoursCharacterAmount = 3,
): string => {
  const hours = Math.floor((value / 3600000) % 1000);
  const minutes = Math.floor((value / 60000) % 60);
  const seconds = Math.floor((value / 1000) % 60);

  return [
    leadZero(hours, hoursCharacterAmount),
    leadZero(minutes, 2),
    leadZero(seconds, 2),
  ].filter((x) => Boolean(x)).join(':');
};

export const convertDurationToValue = (duration: string): number => {
  const matches = duration.trim().match(/(?:(\d{2,3}):([0-5]\d):)([0-5]\d)/i);

  if (!matches) {
    return parseFloat(duration);
  }

  const [hours, minutes, seconds] = matches
    .slice(1).map((x) => parseInt(x, 10) || 0) as MinimumArray<3, number>;

  const value = (
    (hours * 60 * 60 * 1000)
    + (minutes * 60 * 1000)
    + (seconds * 1000)
  );

  return value;
};

export const convertFromValue = (
  value: number,
): string => convertValueToDuration(value);

export const convertToValue = (
  duration: string,
): number => convertDurationToValue(duration);

export const InputTimeDurationUI = memo<Props>((
  props,
) => {
  const {
    className, value, setNewDuration, twoCharactersInHours, ...rest
  } = props;

  const mask = useMemo(() => (
    twoCharactersInHours
      ? '99:99:99'
      : '999:99:99'), [twoCharactersInHours]);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (setNewDuration) {
        setNewDuration(convertDurationToValue(String(target.value)));
      }
    },
    [
      setNewDuration,
    ],
  );

  return (
    <InputMask
      className={cn(className, FormStyles.input)}
      type="text"
      data-qa="input-time-duration"
      {...rest}
      onChange={handleChange}
      defaultValue={convertValueToDuration(
        Number(value),
        twoCharactersInHours
          ? 2
          : undefined,
      )}
      mask={mask}
      maskChar='0'
    />
  );
});

export const InputTimeDuration = withFinalFormController<number>()<Props>(
  InputTimeDurationUI,
);
