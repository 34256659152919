import { useMemo } from 'react';
import {
  type SubscriptionProductPlanType,
  type SubscriptionProductSubscriptionData,
} from '@/components/platform/SubscriptionProduct/typedefs';
import {
  PaymentStatus,
  SubscriptionType,
} from '@/controllers/graphql/generated';
import { useUserCards } from '@/controllers/userCards/userCards.hooks/useUserCards';
import { getSubscriptionStatus } from '@/components/platform/SubscriptionProduct/helpers';
import { useUserSubscriptionsQuery } from '@/components/platform/Subscription/graphql/generated/userSubscriptions.query.generated';

interface Result {
  lastUserSubscription: SubscriptionProductSubscriptionData | null;
  loading: boolean;
}

export const useUserSubscriptionForSubscriptionProduct = (): Result => {
  const { data, loading } = useUserSubscriptionsQuery({
    variables: {
      filter: {
        started: true,
      },
    },
  });

  const [userCards] = useUserCards();

  const cardData = useMemo(() => {
    const primaryCard = userCards.find((card) => card.primary);

    if (!primaryCard) {
      return null;
    }

    return primaryCard;
  }, [userCards]);

  const lastUserSubscription = useMemo(() => {
    const userSubscriptions = data?.userSubscriptions ?? [];

    if (!userSubscriptions.length) {
      return null;
    }

    const lastSubscription = userSubscriptions.find(({ expired }) => !expired);

    if (!lastSubscription) {
      return null;
    }

    const lastSubscriptionPlan = lastSubscription.subscriptionPlan;
    const lastSubscriptionPlanType = lastSubscriptionPlan.type;

    if (
      ![
        SubscriptionType.SubscriptionProductMonthly,
        SubscriptionType.SubscriptionProductWeekly,
        SubscriptionType.SubscriptionProductAnnual,
        SubscriptionType.SubscriptionProductAnnualWithDiscount,
        SubscriptionType.SubscriptionProductQuarterly,
        SubscriptionType.SubscriptionProductTest,
        SubscriptionType.SubscriptionProductUpfrontMonthly,
        SubscriptionType.SubscriptionProductUpfrontQuarterly,
        SubscriptionType.SubscriptionProductUpfrontBiannual,
      ].includes(lastSubscriptionPlanType)
    ) {
      return null;
    }

    const status = getSubscriptionStatus(lastSubscription);

    const price = lastSubscription.price ?? lastSubscriptionPlan.salePrice;
    const { period } = lastSubscription.subscriptionPlan;
    const subscriptionPrice = `$${price}`;

    const nextPaymentDateString = lastSubscription.dateNext
      && new Date(lastSubscription.dateNext)
        .toLocaleDateString(
          undefined, // take user locale
          {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          },
        );

    const acceptedPaymentsCount = lastSubscription.payments
      .reduce(
        (paymentsCount, payment) => (payment.status === PaymentStatus.Accepted
          ? paymentsCount + 1
          : paymentsCount),
        0,
      );

    return {
      id: lastSubscription.id,
      payments: lastSubscription.payments,
      subscriptionType: lastSubscriptionPlanType as SubscriptionProductPlanType,
      subscriptionPlan: lastSubscriptionPlan,
      status,
      nextPaymentDateString: nextPaymentDateString ?? '',
      nextPaymentDate: lastSubscription.dateNext,
      price: subscriptionPrice,
      period,
      acceptedPaymentsCount,
      cardData,
    };
  }, [data?.userSubscriptions, cardData]);

  return {
    lastUserSubscription,
    loading,
  };
};
