import {
  type FC,
  type RefObject,
  useEffect,
  useState,
  useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import { Form } from 'react-final-form';
import { Elements } from '@stripe/react-stripe-js';
import { useSubscriptionDetails } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useSubscriptionDetails';
import { useStripeElementData } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useStripeElementData';
import { type StripeChekoutFormFields } from '@/components/paymentProviders/Stripe/typedefs';
import { StripeCheckoutForm } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/CheckoutForm/StripeCheckoutForm';
import { StripeExpressCheckout } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/ExpressCheckoutForm/StripeExpressCheckout';
import { type ProcessSubscriptionPaymentPricingOption, type ProcessSubscriptionPaymentSubscriptionPlan } from '@/components/platform/SubscriptionProduct/typedefs';
import { analyticsSDK } from '@/controllers/analytics';
import { Alert, AlertMode } from '@/components/ui/Alert';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { CheckoutPaymentProvider, RegistrationType } from '@/controllers/analytics/generated';
import { emptyFunction } from '@/lib/helpers/functional';
import styles from '@/components/paymentProviders/Stripe/StripePayment.module.scss';
import { checkIfPlanIsFreeTrial } from '@/controllers/subscriptionPlans/subscriptionPlans.helpers/checkIfPlanIsFreeTrial';

const StripePaymentDescription = dynamic(
  () => import('@/components/paymentProviders/Stripe/StripeCheckoutForm/components/PaymentDescription/StripePaymentDescription')
    .then((mod) => mod.StripePaymentDescription),
);

interface Props {
  clientSecret: string;
  redirectUrl: string;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  email?: string;
  submitPortalRef?: RefObject<HTMLDivElement> | null;
  omitDescription?: boolean;
  submitButtonText?: string;
  withCardElement?: boolean;
}

export const StripePayment: FC<Props> = ({
  clientSecret,
  redirectUrl,
  subscriptionPlan,
  pricingOption,
  submitPortalRef,
  email,
  omitDescription = false,
  submitButtonText,
  withCardElement = false,
}) => {
  const subscriptionDetails = useSubscriptionDetails(
    subscriptionPlan,
    pricingOption,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation(I18N_CODES.payment);

  const isFreeTrialPlan = checkIfPlanIsFreeTrial({
    subscriptionPlan,
    pricingOption,
  });

  const {
    elementsOptions,
    stripePromise,
  } = useStripeElementData({ clientSecret });

  useEffect(() => {
    if (!isFreeTrialPlan) {
      analyticsSDK.subscriptionProduct.sendCheckoutVisitEvent({
        registrationType: RegistrationType.TemporaryEmail,
        provider: CheckoutPaymentProvider.Stripe,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderForm = useCallback(() => (
    <StripeCheckoutForm
      redirectUrl={redirectUrl}
      subscriptionPlan={subscriptionPlan}
      pricingOption={pricingOption}
      clientSecret={clientSecret}
      submitPortalRef={submitPortalRef}
      setErrorMessage={setErrorMessage}
      email={email}
      submitButtonText={submitButtonText}
      withCardElement={withCardElement}
    />
  ), [
    redirectUrl,
    subscriptionPlan,
    pricingOption,
    clientSecret,
    submitPortalRef,
    setErrorMessage,
    email,
    submitButtonText,
    withCardElement,
  ]);

  return (
    <div className={styles.wrapper}>
      {!omitDescription && (
        <div className={styles.descriptionWrapper}>
          <StripePaymentDescription
            subscriptionDetails={subscriptionDetails}
          />
        </div>
      )}

      <div className={cn(styles.checkoutWrapper, {
        [styles.fullWidth]: omitDescription,
      })}
      >
        {errorMessage && (
          <Alert mode={AlertMode.Error} className={styles.errorBanner}>
            <h1 className={typography.platformH4}>
              {t(`${I18N_CODES.payment}:payment_error_banner_header`)}
            </h1>

            <p className={typography.platformTextSecondary}>{errorMessage}</p>
          </Alert>
        )}

        <Elements stripe={stripePromise} options={elementsOptions}>
          <StripeExpressCheckout
            redirectUrl={redirectUrl}
            setErrorMessage={setErrorMessage}
            subscriptionPlan={subscriptionPlan}
            pricingOption={pricingOption}
            clientSecret={clientSecret}
          />
        </Elements>

        <Elements stripe={stripePromise} options={elementsOptions}>
          <Form<StripeChekoutFormFields>
            onSubmit={emptyFunction}
            render={renderForm}
          />
        </Elements>
      </div>
    </div>
  );
};
