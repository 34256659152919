import React, { type FC, memo } from 'react';
import { cn } from '@/lib';
import styles from '../ProfileDropdownItem.module.scss';

type Props = {
  dataQA: string | undefined;
  isHiddenOnMobile: boolean | undefined;
};

export const ProfileDropdownItemWrapper: FC<Props> = memo(({
  children,
  dataQA = 'profile-dropdown-link',
  isHiddenOnMobile,
}) => (
  <li
    data-qa={dataQA}
    className={cn(
      styles.item,
      {
        [styles.hiddenOnMobile]: isHiddenOnMobile,
      },
    )}
  >
    {children}
  </li>
));
