import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconSelectUsers: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1385_9247)">
        <path d="M11.2857 14.9899V16.8809C10.4453 16.5913 9.54583 16.5025 8.66288 16.6219C7.77992 16.7413 6.93926 17.0653 6.21147 17.5669C5.48369 18.0684 4.89003 18.7327 4.48037 19.5041C4.0707 20.2755 3.85697 21.1314 3.85714 22L2 21.9991C1.99971 20.8943 2.259 19.8041 2.75799 18.812C3.25698 17.8199 3.98244 16.9523 4.87877 16.2756C5.7751 15.5989 6.81852 15.1312 7.92907 14.9082C9.03962 14.6851 10.1878 14.7137 11.2857 14.9899ZM9.42857 13.8571C6.35036 13.8571 3.85714 11.4279 3.85714 8.42857C3.85714 5.42929 6.35036 3 9.42857 3C12.5068 3 15 5.42929 15 8.42857C15 11.4279 12.5068 13.8571 9.42857 13.8571ZM9.42857 12.0476C11.4807 12.0476 13.1429 10.4281 13.1429 8.42857C13.1429 6.42905 11.4807 4.80952 9.42857 4.80952C7.37643 4.80952 5.71429 6.42905 5.71429 8.42857C5.71429 10.4281 7.37643 12.0476 9.42857 12.0476Z" fill="currentColor" />
        <path d="M15.6449 20.9633L16.6631 21.9815L22.7678 15.8768L23.7874 16.8964L16.6631 24.0207L12.0741 19.4317L13.0937 18.4121L14.6261 19.9444L15.6449 20.9626V20.9633ZM15.6464 18.9241L19.2172 15.3525L20.2339 16.3693L16.6631 19.9408L15.6464 18.9241ZM13.6079 23.0018L12.589 24.0207L8 19.4317L9.01961 18.4121L10.0385 19.431L10.0378 19.4317L13.6079 23.0018Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1385_9247">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </BaseIcon>
);
