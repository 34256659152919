import React, { type FC, memo } from 'react';
import {
  PlatformResponsiveModal,
} from '@/components/ui/PlatformResponsiveModal/PlatformResponsiveModal';
import { I18N_CODES } from '@/lib/constants/general';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTranslation } from '@/middleware/i18n';
import { Modal } from '@/components/ui/Modal';
import { cn } from '@/lib';
import {
  SubscriptionPlansList,
} from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList';
import styles
  from '@/components/platform/SubscriptionProduct/components/Paywall/Paywall.module.scss';
import { typography } from '@/components/ui/typography';
import { type PaywallLocations } from '@/controllers/analytics/generated';
import {
  type ClosePaywallOptions,
} from '@/components/platform/SubscriptionProduct/hooks/usePaywallModal';
import {
  usePaywallPricingOptions,
} from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList/hooks/usePaywallPricingOptions';
import {
  StripeCheckoutModal,
} from '@/components/platform/SubscriptionProduct/components/StripeCheckoutModal/StripeCheckoutModal';
import {
  useProceedWithSubscription,
} from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/hooks/useProceedWithSubscription';
import { PlatformSidebarHeight } from '@/components/ui/PlatformSidebar';
import { useIsOpenState } from '@/hooks/useIsOpenState';

interface Props {
  paywallLocation: PaywallLocations;
  isOpen: boolean;
  closePaywall: (options?: ClosePaywallOptions) => void;
}

export const Paywall: FC<Props> = memo(({
  paywallLocation,
  isOpen,
  closePaywall,
}) => {
  const { isSubscriptionProductDomain } = useSubDomainContext();
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    pricingOptions,
    selectedOption,
    setSelectedOption,
    loading,
  } = usePaywallPricingOptions();

  const {
    open: openCheckout,
    close: closeCheckout,
    isOpen: isCheckoutOpened,
  } = useIsOpenState();

  const {
    createSubscriptionPayment,
    loading: paymentLoading,
    result: paymentResult,
    called,
  } = useProceedWithSubscription({
    planId: selectedOption?.subscriptionPlan.id,
    pricingOptionId: selectedOption?.id,
    isBuiltInCheckout: true,
  });

  if (
    !isSubscriptionProductDomain
    || !pricingOptions
  ) {
    return null;
  }

  if (selectedOption && isCheckoutOpened) {
    return (
      <StripeCheckoutModal
        isOpen={isCheckoutOpened}
        closeModal={closeCheckout}
        subscriptionPlan={selectedOption.subscriptionPlan}
        pricingOption={selectedOption}
        isLoading={paymentLoading || !called}
        clientSecret={paymentResult?.clientSecret}
        redirectUrl={paymentResult?.redirect}
      />
    );
  }

  return (
    <PlatformResponsiveModal
      isOpen={isOpen}
      modalClassName={styles.paywallModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldCloseOnSwipe
      dataQa='modal'
      closeModal={closePaywall}
      modalType={Modal.type.Paywall}
      sidebarVhHeight={PlatformSidebarHeight.Vh80}
      renderTitle={() => (
        <h3
          data-qa='paywall-modal-title'
          className={cn(styles.title, typography.platformH1)}
        >
          {t(`${I18N_CODES.common}:paywall.title`)}
        </h3>
      )}
    >
      <SubscriptionPlansList
        paywallLocation={paywallLocation}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        pricingOptions={pricingOptions}
        openCheckout={openCheckout}
        plansLoading={loading}
        paymentLoading={paymentLoading}
        createSubscriptionPayment={createSubscriptionPayment}
      />
    </PlatformResponsiveModal>
  );
});
