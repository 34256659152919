import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconChevronRight: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.29504 16.8849L12.875 12.2949L8.29504 7.70492L9.70504 6.29492L15.705 12.2949L9.70504 18.2949L8.29504 16.8849Z"
      fill="currentColor"
    />
  </BaseIcon>
);
