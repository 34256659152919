import { useCallback, type ClipboardEvent } from 'react';

const convertToPlainText = (element: Node): string => {
  if (element.childNodes.length > 0) {
    let processedText = '';

    const children = Array.from(element.childNodes);

    children.forEach((child) => {
      processedText += convertToPlainText(child);
    });

    return processedText;
  }

  return element.textContent || '';
};

const getSelectedText = (selection: Selection | null) => {
  if (!selection?.rangeCount) {
    return null;
  }

  let processedText = '';
  const container = document.createElement('div');

  for (let i = 0; i < selection.rangeCount; i += 1) {
    const htmlContent = selection.getRangeAt(i).cloneContents();

    processedText += convertToPlainText(htmlContent);
    container.appendChild(htmlContent);
  }

  return {
    plain: processedText,
    html: container.innerHTML,
  };
};

export const useCopyTextAsHTML = () => (
  useCallback((event: ClipboardEvent<HTMLDivElement>) => {
    if (typeof window === 'undefined') {
      return;
    }

    const selection = window.getSelection();
    const selectedText = getSelectedText(selection);

    if (selectedText) {
      event.preventDefault();

      event.clipboardData.setData('text/plain', selectedText.plain);
      event.clipboardData.setData('text/html', selectedText.html);
    }
  }, [])
);
