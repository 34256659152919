import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconBulb: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.97296 18H11V13H13V18H14.027C14.159 16.798 14.772 15.806 15.767 14.723C15.88 14.601 16.599 13.856 16.684 13.75C17.3902 12.8676 17.8329 11.8037 17.9611 10.6807C18.0892 9.55781 17.8976 8.42151 17.4082 7.40271C16.9189 6.38392 16.1517 5.52406 15.1951 4.92215C14.2384 4.32025 13.1313 4.00078 12.001 4.00054C10.8708 4.0003 9.7635 4.31929 8.80662 4.92079C7.84974 5.52228 7.08221 6.38182 6.59242 7.4004C6.10263 8.41899 5.9105 9.55521 6.03815 10.6782C6.1658 11.8012 6.60805 12.8653 7.31396 13.748C7.39996 13.855 8.12096 14.601 8.23196 14.722C9.22796 15.806 9.84096 16.798 9.97296 18ZM9.99996 20V21H14V20H9.99996ZM5.75396 15C4.81215 13.8233 4.22191 12.4044 4.05122 10.9069C3.88054 9.40944 4.13636 7.89418 4.78921 6.53572C5.44207 5.17725 6.46539 4.03085 7.74132 3.22856C9.01724 2.42627 10.4938 2.00074 12.001 2.00098C13.5082 2.00122 14.9847 2.42722 16.2604 3.22992C17.536 4.03262 18.559 5.17935 19.2114 6.53803C19.8638 7.8967 20.1192 9.41204 19.948 10.9095C19.7768 12.4069 19.1861 13.8256 18.244 15.002C17.624 15.774 16 17 16 18.5V21C16 21.5304 15.7892 22.0391 15.4142 22.4142C15.0391 22.7893 14.5304 23 14 23H9.99996C9.46953 23 8.96082 22.7893 8.58575 22.4142C8.21067 22.0391 7.99996 21.5304 7.99996 21V18.5C7.99996 17 6.37496 15.774 5.75396 15Z"
      fill="currentColor"
    />
  </BaseIcon>
);
