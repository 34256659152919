import { type RouterParams } from '@/controllers/router/router.typedefs';

export type TopicLinkFn = (
  courseSlug: string, topicSlug: string, mainRoute: string, section?: string
) => RouterParams;

export const getCourseTopicLinkParams: TopicLinkFn = (courseSlug, topicSlug, mainRoute: string, section = '') => ({
  href: `${mainRoute}/${courseSlug}/${topicSlug}${section
    ? `?section=${section}`
    : ''}`,
});
