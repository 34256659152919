import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsApplyingDark: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={199} initialWidth={117} {...props}>
    <g clipPath="url(#clip0_19530_83971)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.6302 50.6267C65.4777 51.0974 48.6549 63.467 44.6975 68.2107C44.6975 68.2107 38.0221 54.6368 37.8007 54.2032C36.0875 50.766 40.1382 43.4226 31.7915 39.912C28.0802 38.343 25.9283 41.5321 28.7034 43.8967C29.1277 44.3124 29.6415 44.6261 30.2059 44.8138L26.5779 44.8174C23.1744 45.0391 12.154 47.1184 12.3748 49.8987C12.531 51.888 13.6714 51.6741 13.9269 51.8116C13.7396 51.9286 13.5811 52.0863 13.4635 52.2729C13.3459 52.4595 13.2722 52.6702 13.2478 52.8892C13.2234 53.1082 13.249 53.3299 13.3227 53.5376C13.3964 53.7453 13.5163 53.9337 13.6734 54.0887C14.922 55.5031 16.6714 54.5629 15.9839 55.0201C14.5297 55.949 15.932 58.6952 17.9583 58.1524C23.4733 56.6478 25.7965 58.2022 26.9433 60.2214C31.9821 69.0969 35.6771 80.2915 39.2147 83.5564C47.4047 90.9729 55.1723 80.0287 64.5175 61.3148C67.7587 54.8074 71.9838 59.3836 67.3334 53.8035C67.1412 53.5388 65.6302 50.6267 65.6302 50.6267Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M65.6302 50.6267C65.4777 51.0974 48.6549 63.467 44.6975 68.2107C44.6975 68.2107 38.0221 54.6368 37.8007 54.2032C36.0875 50.766 40.1382 43.4226 31.7915 39.912C28.0802 38.343 25.9283 41.5321 28.7034 43.8967C29.1277 44.3124 29.6415 44.6261 30.2059 44.8138L26.5779 44.8174C23.1744 45.0391 12.154 47.1184 12.3748 49.8987C12.531 51.888 13.7444 51.3625 13.9999 51.5C13.8126 51.617 13.6175 51.8134 13.4999 52C13.3823 52.1866 13.2722 52.6702 13.2478 52.8892C13.2234 53.1082 13.249 53.3299 13.3227 53.5376C13.3964 53.7453 13.5163 53.9337 13.6734 54.0887C14.922 55.5031 16.6714 54.5629 15.9839 55.0201C14.5297 55.949 15.932 58.6952 17.9583 58.1524C23.4733 56.6478 25.7965 58.2022 26.9433 60.2214C31.9821 69.0969 35.6771 80.2915 39.2147 83.5564C47.4047 90.9729 55.1723 80.0287 64.5175 61.3148C67.7587 54.8074 71.9838 59.3836 67.3334 53.8035C67.1412 53.5388 65.6302 50.6267 65.6302 50.6267Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.762 68.2938C45.0195 68.686 46.8725 72.0988 47.5157 73.0645C46.6344 71.6214 46.184 70.0517 46.1937 68.4575C46.1937 68.4575 46.3154 67.0701 46.1477 67.2629C45.7516 67.7729 45.2874 68.254 44.762 68.6989C44.7452 68.6113 44.8136 68.233 44.762 68.2938Z"
        fill="#191A1F"
      />
      <path
        d="M44.4997 67.9998C44.7572 68.392 46.8725 72.0988 47.5157 73.0645C46.6344 71.6214 46.184 70.0517 46.1937 68.4575C46.1937 68.4575 46.3154 67.0701 46.1477 67.2629C45.7516 67.7729 45.3621 68.1161 44.8367 68.561C44.8199 68.4734 44.5512 67.939 44.4997 67.9998Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0001 51.5002C16.4251 50.2649 18.4774 49.8698 21.1704 49.6143"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M16.0001 54.9999C16.7004 54.7337 16.877 53.6935 21.3208 53.5225"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M29.4998 44.5001C29.6681 46.1372 29.2824 45.5286 28.1264 47.4071C27.5847 48.3118 27.2257 49.3176 27.0705 50.3657"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6898 50.8901L61.3198 54.0501C59.3198 63.3301 56.3198 68.7701 54.7598 77.8601C56.7598 76.2001 58.1998 74.8601 60.2098 73.1601L64.6898 50.8901Z"
        fill="#191A1F"
      />
      <path
        d="M64.6898 50.8901L61.4998 54.0001C59.4998 63.2801 56.3198 68.7701 54.7598 77.8601C56.7598 76.2001 58.1998 74.8601 60.2098 73.1601L64.6898 50.8901Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1202 181.37H80.1202C80.2884 181.375 80.4483 181.444 80.5672 181.563C80.6862 181.682 80.7552 181.842 80.7602 182.01L81.7602 196.17H48.1302C47.5002 190.24 51.8502 188.44 56.9702 185.9C59.0002 184.43 59.1202 181.37 59.1202 181.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M59.1202 181.37H80.1202C80.2884 181.375 80.4483 181.444 80.5672 181.563C80.6862 181.682 80.7552 181.842 80.7602 182.01L81.7602 196.17H48.1302C47.5002 190.24 51.8502 188.44 56.9702 185.9C59.0002 184.43 59.1202 181.37 59.1202 181.37Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.12 193.9H48.14C47.7865 193.9 47.5 194.186 47.5 194.54V197.57C47.5 197.923 47.7865 198.21 48.14 198.21H82.12C82.4735 198.21 82.76 197.923 82.76 197.57V194.54C82.76 194.186 82.4735 193.9 82.12 193.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M82.12 193.9H48.14C47.7865 193.9 47.5 194.186 47.5 194.54V197.57C47.5 197.923 47.7865 198.21 48.14 198.21H82.12C82.4735 198.21 82.76 197.923 82.76 197.57V194.54C82.76 194.186 82.4735 193.9 82.12 193.9Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.7999 181.37H100.8C100.967 181.377 101.126 181.447 101.244 181.566C101.363 181.684 101.433 181.843 101.44 182.01L102.44 196.17H68.8099C68.1799 190.24 72.5299 188.44 77.6499 185.9C79.6399 184.43 79.7999 181.37 79.7999 181.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M79.7999 181.37H100.8C100.967 181.377 101.126 181.447 101.244 181.566C101.363 181.684 101.433 181.843 101.44 182.01L102.44 196.17H68.8099C68.1799 190.24 72.5299 188.44 77.6499 185.9C79.6399 184.43 79.7999 181.37 79.7999 181.37Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.8 193.9H68.8197C68.4662 193.9 68.1797 194.186 68.1797 194.54V197.57C68.1797 197.923 68.4662 198.21 68.8197 198.21H102.8C103.153 198.21 103.44 197.923 103.44 197.57V194.54C103.44 194.186 103.153 193.9 102.8 193.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M102.8 193.9H68.8197C68.4662 193.9 68.1797 194.186 68.1797 194.54V197.57C68.1797 197.923 68.4662 198.21 68.8197 198.21H102.8C103.153 198.21 103.44 197.923 103.44 197.57V194.54C103.44 194.186 103.153 193.9 102.8 193.9Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0002 20.7498C65.0002 29.2998 65.0002 29.1598 65.0002 37.7498C65.0002 60.3498 54.7202 65.1198 54.0702 88.3798C53.9502 92.6298 57.7602 92.0998 58.4002 97.8098C63.2102 98.9798 76.0502 94.2798 80.8502 95.4498C85.6402 94.2798 98.2802 103.78 103.07 102.61C111.48 65.6798 93.7302 58.4898 93.7602 39.2498V20.7898C93.8202 1.79984 65.0602 2.44984 65.0002 20.7498Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M65.0002 20.7498C65.0002 29.2998 65.0002 29.1598 65.0002 37.7498C65.0002 60.3498 54.7202 65.1198 54.0702 88.3798C53.9502 92.6298 57.7602 92.0998 58.4002 97.8098C63.2102 98.9798 76.0502 94.2798 80.8502 95.4498C85.6402 94.2798 98.2802 103.78 103.07 102.61C111.48 65.6798 93.7302 58.4898 93.7602 39.2498V20.7898C93.8202 1.79984 65.0602 2.44984 65.0002 20.7498Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0001 20.7502C65.0001 29.3002 65.0001 29.1602 65.0001 37.7502C65.0082 40.263 64.8679 42.774 64.5801 45.2702H94.3501C93.9499 43.2955 93.7522 41.2851 93.7601 39.2702V20.8102C93.8201 1.80021 65.0601 2.45021 65.0001 20.7502Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M65.0001 20.7502C65.0001 29.3002 65.0001 29.1602 65.0001 37.7502C65.0082 40.263 64.8679 42.774 64.5801 45.2702H94.3501C93.9499 43.2955 93.7522 41.2851 93.7601 39.2702V20.8102C93.8201 1.80021 65.0601 2.45021 65.0001 20.7502Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.7299 19.2298C92.2999 2.35982 66.6299 2.90982 65.1099 19.2298H93.7299Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M93.7299 19.2298C92.2999 2.35982 66.6299 2.90982 65.1099 19.2298H93.7299Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.05 87.7197C105.05 87.9297 105.05 88.1297 105.05 88.3397C105.23 98.0097 102.11 103.34 102.14 112.17C102.14 131.32 102.72 180.55 102.66 181.55H58.0001C58.0001 181.55 57.6 131.44 57.88 112.66C58.02 102.66 55.1201 99.7997 54.0401 88.4497C54.0251 88.2066 54.0251 87.9628 54.0401 87.7197H105.05Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M105.05 87.7197C105.05 87.9297 105.05 88.1297 105.05 88.3397C105.23 98.0097 102.11 103.34 102.14 112.17C102.14 131.32 102.72 180.55 102.66 181.55H58.0002C58.0002 181.55 57.6002 131.44 57.8802 112.66C58.0202 102.66 55.1202 99.7997 54.0402 88.4497C54.0252 88.2066 54.2876 87.9628 54.3025 87.7197H105.05Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.3101 30.7101C66.6387 30.71 66.9599 30.613 67.2336 30.4312C67.5073 30.2494 67.7213 29.9909 67.8488 29.6881C67.9763 29.3853 68.0116 29.0516 67.9504 28.7287C67.8891 28.4059 67.734 28.1084 67.5044 27.8733C67.2748 27.6382 66.981 27.4761 66.6598 27.4072C66.3385 27.3383 66.004 27.3658 65.6983 27.486C65.3925 27.6063 65.129 27.8142 64.9408 28.0835C64.7526 28.3528 64.648 28.6716 64.6401 29.0001C64.6348 29.2228 64.674 29.4442 64.7556 29.6515C64.8371 29.8587 64.9593 30.0476 65.1149 30.2069C65.2705 30.3663 65.4564 30.4929 65.6617 30.5793C65.8669 30.6657 66.0874 30.7102 66.3101 30.7101Z"
        fill="white"
      />
      <path
        d="M65.0308 30.1141C65.2421 30.3657 65.5229 30.5494 65.8381 30.6422C66.1533 30.7351 66.4888 30.7328 66.8028 30.6358C67.1167 30.5388 67.395 30.3514 67.6029 30.097C67.8108 29.8425 67.9391 29.5324 67.9716 29.2055C68.0041 28.8785 67.9394 28.5492 67.7857 28.2588C67.632 27.9685 67.396 27.7299 67.1073 27.573C66.8186 27.4161 66.49 27.3478 66.1627 27.3767C65.8355 27.4057 65.524 27.5305 65.2673 27.7356C65.0933 27.8746 64.9489 28.0471 64.8425 28.2428C64.7362 28.4384 64.67 28.6534 64.648 28.875C64.6259 29.0966 64.6485 29.3204 64.7142 29.5332C64.78 29.746 64.8876 29.9435 65.0308 30.1141V30.1141Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.3297 27.3701C79.9977 27.3702 79.6733 27.4692 79.3979 27.6546C79.1224 27.8399 78.9085 28.1031 78.7833 28.4106C78.6582 28.7181 78.6276 29.0559 78.6953 29.3809C78.763 29.7059 78.9261 30.0033 79.1637 30.2352C79.4013 30.4671 79.7026 30.6229 80.0291 30.6828C80.3556 30.7426 80.6926 30.7038 80.997 30.5712C81.3013 30.4387 81.5593 30.2184 81.7379 29.9386C81.9165 29.6587 82.0076 29.332 81.9997 29.0001C81.9892 28.5642 81.8087 28.1496 81.4966 27.845C81.1846 27.5404 80.7658 27.37 80.3297 27.3701Z"
        fill="white"
      />
      <path
        d="M81.4889 27.8371C81.2498 27.6068 80.9474 27.4531 80.6205 27.3954C80.2936 27.3378 79.9569 27.3789 79.6534 27.5135C79.3499 27.6481 79.0935 27.8701 78.9168 28.1511C78.7401 28.4322 78.6511 28.7595 78.6613 29.0913C78.6715 29.4231 78.7804 29.7444 78.974 30.0141C79.1676 30.2837 79.4372 30.4896 79.7484 30.6053C80.0595 30.721 80.3981 30.7414 80.7209 30.6638C81.0437 30.5862 81.336 30.4141 81.5605 30.1696C81.8555 29.8484 82.0131 29.4246 81.9997 28.9888C81.9864 28.5529 81.803 28.1396 81.4889 27.8371Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6099 112.87C79.6099 108.78 72.8899 109.16 70.4399 103.35"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9398 22.5598C66.2809 22.4615 65.6077 22.5662 65.0098 22.8598"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M79.6299 22.5603C80.2887 22.4639 80.9614 22.5684 81.5599 22.8603"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.52 42.6401H65C64.4696 42.6401 63.9609 42.8508 63.5858 43.2259C63.2107 43.601 63 44.1097 63 44.6401C63 45.1706 63.2107 45.6793 63.5858 46.0544C63.9609 46.4294 64.4696 46.6401 65 46.6401H93.5C94.0304 46.6401 94.5391 46.4294 94.9142 46.0544C95.2893 45.6793 95.5 45.1706 95.5 44.6401C95.5 44.1097 95.2893 43.601 94.9142 43.2259C94.5391 42.8508 94.0304 42.6401 93.5 42.6401H93.52Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M93.52 42.6401H65C64.4696 42.6401 63.9609 42.8508 63.5858 43.2259C63.2107 43.601 63 44.1097 63 44.6401C63 45.1706 63.2107 45.6793 63.5858 46.0544C63.9609 46.4294 64.4696 46.6401 65 46.6401H93.5C94.0304 46.6401 94.5391 46.4294 94.9142 46.0544C95.2893 45.6793 95.5 45.1706 95.5 44.6401C95.5 44.1097 95.2893 43.601 94.9142 43.2259C94.5391 42.8508 94.0304 42.6401 93.5 42.6401H93.52Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M93.7902 19.0898H49.9502"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.18 111.4L93.6601 72.8198C93.5501 73.5698 94.5601 80.7098 95.5001 87.7198C95.5001 87.9798 96.5001 87.4198 96.5601 87.7198C98.3801 101.28 101.64 118.04 99.6201 131.4C99.0301 135.31 101.84 136.33 101.7 136.04C101.037 134.541 100.872 132.869 101.23 131.27C101.59 129.27 102.91 128.27 102.23 127.27L102.18 111.4Z"
        fill="#191A1F"
      />
      <path
        d="M102.18 111.4L93.6601 72.8198C93.5501 73.5698 94.5601 80.7098 95.5001 87.7198C95.5001 87.9798 96.5001 87.4198 96.5601 87.7198C98.3801 101.28 101.64 118.04 99.6201 131.4C99.0301 135.31 101.84 136.33 101.7 136.04C101.037 134.541 100.872 132.869 101.23 131.27C101.59 129.27 102.91 128.27 102.23 127.27L102.18 111.4Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6299 46.5898L64.1899 48.3598C69.7549 47.4606 75.3679 46.8897 80.9999 46.6498L64.6299 46.5898Z"
        fill="#191A1F"
      />
      <path
        d="M64.6299 46.5898L64.1899 48.3598C69.7549 47.4606 75.3679 46.8897 80.9999 46.6498L64.6299 46.5898Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.9398 181.51L58.5098 183.28C64.0646 182.382 69.6677 181.814 75.2898 181.58L58.9398 181.51Z"
        fill="#191A1F"
      />
      <path
        d="M58.9398 181.51L58.5098 183.28C64.0646 182.382 69.6677 181.814 75.2898 181.58L58.9398 181.51Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.8997 181.51L79.4697 183.28C85.0245 182.382 90.6277 181.814 96.2497 181.58L79.8997 181.51Z"
        fill="#191A1F"
      />
      <path
        d="M79.8997 181.51L79.4697 183.28C85.0245 182.382 90.6277 181.814 96.2497 181.58L79.8997 181.51Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.48 19L65 20.79C70.5583 19.8916 76.1647 19.3206 81.79 19.08L65.48 19Z"
        fill="#191A1F"
      />
      <path
        d="M65.48 19L65 20.79C70.5583 19.8916 76.1647 19.3206 81.79 19.08L65.48 19Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M65.6988 28.5826C65.665 28.5461 65.6189 28.5235 65.5695 28.5192C65.52 28.5149 65.4707 28.5291 65.4311 28.5591C65.3925 28.5915 65.3684 28.6379 65.364 28.6881C65.3596 28.7383 65.3753 28.7882 65.4077 28.8268C65.4229 28.8472 65.4422 28.8641 65.4643 28.8764C65.4865 28.8888 65.511 28.8963 65.5363 28.8985C65.5616 28.9007 65.587 28.8976 65.611 28.8892C65.635 28.8809 65.6569 28.8676 65.6754 28.8502C65.714 28.8178 65.7381 28.7715 65.7425 28.7213C65.7469 28.6711 65.7312 28.6212 65.6988 28.5826Z"
        fill="#191A1F"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.2237 28.9479C79.26 28.9829 79.3087 29.002 79.3591 29.0011C79.4095 29.0002 79.4574 28.9792 79.4924 28.943C79.5274 28.9067 79.5465 28.858 79.5455 28.8076C79.5446 28.7572 79.5237 28.7093 79.4874 28.6743C79.4511 28.6393 79.4024 28.6202 79.352 28.6212C79.3017 28.6221 79.2537 28.643 79.2188 28.6793C79.1838 28.7156 79.1647 28.7643 79.1656 28.8147C79.1666 28.865 79.1875 28.913 79.2237 28.9479Z"
        fill="#191A1F"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.5801 181.55V112.74"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.19 136.46C109.88 136.27 107.66 141.91 108.03 142.95C108.73 144.95 112 142.5 112.28 142.14C112.56 141.69 115 144.5 116.52 136.62C116.61 134.06 114.82 124.48 114.63 121C113.76 105.05 108.02 65.1899 94.7 48.4399C93.7 47.1899 92.86 48.0299 92.03 48.2199C90.73 48.5199 89.54 49.3399 88.26 53.8699C86.91 58.6499 89.09 70.1799 93.2 72.8199C96.31 87.2899 101.1 104.89 101.77 119.76C101.91 122.94 102.28 125.17 101.77 127.01C101.34 128.56 100.12 129.66 99.3201 132.56C98.7801 134.56 101.62 139.32 103.12 138.65C105.8 137.453 103.24 131.84 103.5 131C103.76 130.16 106.93 130.01 107.51 129.74C108.85 129 109.41 134.46 109.18 136.46"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M109.23 135.31C109.273 137.441 108.853 139.556 108 141.51C107.46 142.88 108.33 143.51 109.33 143.58C110.73 143.73 111.82 142.72 112.87 139.93C113.659 137.537 113.969 135.012 113.78 132.5C113.66 131.06 115.49 130.7 115.32 128.5C114.08 117.85 112.62 75.7299 95.0801 48.1699"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.19 136.46C109.42 134.46 108.86 129 107.52 129.74C106.94 130.01 105.66 133.29 105.4 134.13C104.98 135.47 104.86 137.05 104.54 137.77C103.47 140.22 100.25 137.05 99.71 134.86C98.71 130.65 101.59 127.55 101.78 127.01C102.41 125.19 101.87 122.01 101.78 119.76C101.11 104.89 96.32 87.2898 93.21 72.8198"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.05 130.83C109.92 133.83 109.05 142.29 106.29 143.93C104.95 144.74 103.16 144.11 102.95 143.23C102.46 141.1 103.61 140.54 104.17 137.46C104.64 134.776 104.674 132.034 104.27 129.34C103.88 127.26 106.54 125.82 108.49 129.54C108.729 129.945 108.917 130.379 109.05 130.83Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M109.05 130.83C109.92 133.83 109.05 142.29 106.29 143.93C104.95 144.74 103.16 144.11 102.95 143.23C102.46 141.1 103.61 140.54 104.17 137.46C104.64 134.777 104.705 133.524 104.3 130.83C103.91 128.75 106.54 125.82 108.49 129.54C108.729 129.946 108.917 130.379 109.05 130.83Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M114.91 125C114.81 125.54 116.77 132.37 116.38 137.17C116.17 139.84 114.54 143.3 112.28 142.1"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.19 123.72C106.123 123.777 105.12 124.248 104.394 125.032C103.667 125.816 103.275 126.852 103.3 127.92C103.264 128.442 103.332 128.966 103.5 129.461C103.669 129.957 103.935 130.413 104.282 130.804C104.629 131.196 105.052 131.514 105.523 131.739C105.995 131.965 106.508 132.095 107.03 132.12C108.097 132.063 109.1 131.593 109.827 130.809C110.553 130.025 110.945 128.989 110.92 127.92C110.957 127.398 110.889 126.874 110.72 126.379C110.551 125.884 110.286 125.427 109.938 125.036C109.591 124.645 109.169 124.327 108.697 124.101C108.225 123.875 107.713 123.746 107.19 123.72Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.86 124.44C113.04 124.44 110.03 131.84 112.98 132.12C115.93 132.4 114.13 137.12 114.98 137.12C115.83 137.12 115.98 134.48 115.98 133.26C115.98 132.04 114.67 124.41 113.86 124.41"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M104.4 131.41C104.4 130.54 103.65 130.26 103.51 131.59C103.412 132.738 103.701 133.885 104.33 134.85"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M71 36H76.06C76.06 36.671 75.7934 37.3145 75.319 37.789C74.8445 38.2634 74.201 38.53 73.53 38.53C72.859 38.53 72.2155 38.2634 71.741 37.789C71.2666 37.3145 71 36.671 71 36Z"
        fill="#191A1F"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M24.25 19C24.25 18.3099 23.6901 17.75 23 17.75H17C16.3099 17.75 15.75 18.3099 15.75 19V20.75H11C10.3099 20.75 9.75 21.3099 9.75 22V35C9.75 35.6901 10.3099 36.25 11 36.25H29C29.6901 36.25 30.25 35.6901 30.25 35V22C30.25 21.3099 29.6901 20.75 29 20.75H24.25V19ZM14.25 33.75V23.25H15.75V33.75H14.25ZM24.25 33.75V23.25H25.75V33.75H24.25ZM18.25 20.75V20.25H21.75V20.75H18.25Z"
        fill="url(#paint0_linear_19530_83971)"
        stroke="#191A1F"
        strokeWidth="0.5"
      />
      <path
        d="M20.0659 7.66508L20.089 0.999871"
        stroke="#B5B6BA"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M37.788 13.8573L34.8033 15.6826"
        stroke="#B5B6BA"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M1.21204 13.3632L4.1967 15.1885"
        stroke="#B5B6BA"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_19530_83971"
        x1="30"
        y1="18"
        x2="17.8176"
        y2="40.2754"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7246" />
        <stop offset="1" stopColor="#D12B17" />
      </linearGradient>
      <clipPath id="clip0_19530_83971">
        <rect width="117" height="199" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
