import React, { memo, useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import without from 'lodash/without';
import { type PluggableList } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeSlug from 'rehype-slug';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib';
import { transformImageUri } from '@/components/platform/Markdown/Markdown.helpers';
import { type MateMarkdownProps } from '@/components/platform/Markdown/Markdown.typedefs';
import {
  Code as CodeComponent,
  A as AComponent,
  type CodeProps,
  Mark,
  Pre,
  Sup,
  Sub,
  Summary,
  Details,
  type AProps,
} from '@/components/platform/Markdown/components';
import {
  ALLOWED_MARKDOWN_ELEMENTS,
} from '@/components/platform/Markdown/Markdown.constants';

export const MateMarkdown = memo<MateMarkdownProps>((props) => {
  const {
    className: propsClassName,
    isHeadingsAsLinks,
    allowedElements = ALLOWED_MARKDOWN_ELEMENTS,
    disallowedElements,
    skipHtml,
    onClick,
    components,
    customHighlighterStyles,
    source,
  } = props;

  const rehypePlugins: PluggableList = useMemo(() => {
    if (skipHtml) {
      return [];
    }

    if (isHeadingsAsLinks) {
      return [rehypeRaw, rehypeSlug, [rehypeAutolinkHeadings, { behavior: 'before' }]];
    }

    return [rehypeRaw];
  }, [skipHtml, isHeadingsAsLinks]);

  const verifiedAllowedElements = useMemo(() => (
    disallowedElements?.length
      ? without(allowedElements, ...disallowedElements)
      : allowedElements
  ), [allowedElements, disallowedElements]);

  const Code = useCallback((codeProps: CodeProps) => (
    <CodeComponent
      {...codeProps}
      customHighlighterStyles={customHighlighterStyles}
    />
  ), [customHighlighterStyles]);

  const A = useCallback((aProps: AProps) => (
    <AComponent {...aProps} onClick={onClick} />
  ), [
    onClick,
  ]);

  return (
    <NoSSR>
      <ReactMarkdown
        className={cn(propsClassName, 'markdown')}
        includeElementIndex
        rawSourcePos
        remarkPlugins={[remarkGfm]}
        rehypePlugins={rehypePlugins}
        transformImageUri={transformImageUri}
        allowedElements={verifiedAllowedElements}
        unwrapDisallowed
        components={{
          code: Code,
          mark: Mark,
          pre: Pre,
          sup: Sup,
          sub: Sub,
          details: Details,
          a: A,
          summary: Summary,
          ...components,
        }}
      >
        {source}
      </ReactMarkdown>
    </NoSSR>
  );
});

MateMarkdown.displayName = 'MateMarkdown';
