import { type GrantedPermissionWithResources } from '@/controllers/graphql/generated';
import { type Permission } from '@/controllers/user/Permission';
import { useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';

type UseHasAccessHookOutput = boolean;

export const useHasAccess = (
  permissionName: Permission,
  resourceSlug?: string[],
): UseHasAccessHookOutput => {
  const { data } = useAuthUserQuery();
  const grantedPermissions = data?.authUser?.grantedPermissions ?? [];

  return checkPermission(
    grantedPermissions,
    permissionName,
    resourceSlug,
  );
};

export function checkPermission(
  grantedPermissions: GrantedPermissionWithResources[],
  permissionName: Permission,
  resourceSlug?: string[],
): UseHasAccessHookOutput {
  const grantedPermission = grantedPermissions
    .find(({ permission: currentPermission }) => (
      currentPermission === permissionName
    ));

  if (!grantedPermission) {
    return false;
  }

  const {
    any,
    slug: permissionSlugs,
  } = grantedPermission.resources;

  if (!resourceSlug || any) {
    return true;
  }

  return (permissionSlugs ?? [])
    .some((currentSlug) => resourceSlug.includes(currentSlug));
}

export const useCheckPermissions = (
  permissions: Permission[],
  resourceSlug?: string[],
): UseHasAccessHookOutput => {
  const { data } = useAuthUserQuery();

  const grantedPermissions = data?.authUser?.grantedPermissions ?? [];

  return permissions.some((permissionName) => (
    checkPermission(
      grantedPermissions,
      permissionName,
      resourceSlug,
    )
  ));
};

export const userAccessController = {
  useHasAccess,
};
