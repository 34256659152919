import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { DynamicFaviconHandler } from '@/components/common/NotificationsIndications/DynamicFaviconHandler';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { TitleHandler } from '@/components/common/NotificationsIndications/TitleHandler';

export const NotificationIndications = () => {
  const [authUser] = useAuthUser({ ssr: false });
  const { isEnabled } = useFeatures();

  const isDynamicFaviconEnabled = isEnabled(Features.DynamicFavicon);
  const isDynamicTitleEnabled = isEnabled(Features.DynamicTitle);

  if (
    !authUser
    || (!isDynamicFaviconEnabled && !isDynamicTitleEnabled)
  ) {
    return null;
  }

  return (
    <>
      {isDynamicFaviconEnabled && <DynamicFaviconHandler />}

      {isDynamicTitleEnabled && <TitleHandler />}
    </>
  );
};
