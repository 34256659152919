import React, { type FC, memo } from 'react';
import { LogoProduct } from '@/components/common/Logo';
import { type UserNotificationSenderBaseFragment } from '@/components/platform/Notifications/graphql/generated/UserNotificationSenderBase.fragment.generated';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { type UserNotificationsCustomImages } from '@/controllers/graphql/generated';
import { CustomImageComponentsMap } from '@/components/platform/Notifications/constants';
import { cn } from '@/lib';
import styles from './NotificationIcon.module.scss';

interface Props {
  sender?: UserNotificationSenderBaseFragment | null;
  customImageType?: UserNotificationsCustomImages;
}

export const NotificationIcon: FC<Props> = memo(({
  sender,
  customImageType,
}) => {
  if (customImageType) {
    const CustomImageComponent = CustomImageComponentsMap[customImageType];

    if (CustomImageComponent) {
      return (
        <div
          className={cn(
            styles.background,
            styles[`background--${customImageType}`],
          )}
        >
          <CustomImageComponent className={styles.customImage} />
        </div>
      );
    }
  }

  return (sender
    ? (
      <UserAvatar
        userId={sender.id}
        shouldOpenProfile={false}
        fullName={sender.username}
        avatar={sender.avatar}
        size={28}
      />
    )
    : <LogoProduct className={styles.icon} />
  );
});
