import { ROUTES } from '@/controllers/router/router.contants';
import { type RouterParams } from '@/controllers/router/router.typedefs';
import { renderRoute, allRoutes } from '@/controllers/router/allRoutes';

export const singleApplication = (
  courseUserId: number | string,
): RouterParams => ({
  href: renderRoute(
    allRoutes.learn.application.selectedApplication,
    { courseUserId },
  ),
});

export const newApplication: RouterParams = {
  href: `${renderRoute(allRoutes.learn.application, {})}/${ROUTES.applicationSurvey.new.query}`,
};
