import React, { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { typography } from '@/components/ui/typography';
import styles from '@/components/platform/Notifications/components/NotificationsContent/NotificationsDropdownContent/NotificationsDropDownContent.module.scss';
import { useNotificationsInfiniteScroll } from '@/components/platform/Notifications/hooks/useNotificationsInfiniteScroll';
import { MarkNotificationsAsReadButton } from '@/components/platform/Notifications/components/NotificationsContent/MarkNotificationsAsReadButton';
import { NotificationsContentBody } from '@/components/platform/Notifications/components/NotificationsContent/NotificationsContentBody';
import { useNotifications } from '@/components/platform/Notifications/hooks/useNotifications';

export const NotificationsDropdownContent: FC = () => {
  const { t } = useTranslation([I18N_CODES.platformNotifications]);

  const {
    notifications,
    loadOlderNotifications,
    loading,
    hasMoreOlderNotifications,
    unreadNotificationsCount,
  } = useNotifications();

  const {
    listRef,
    newerNotificationsTriggerRef,
  } = useNotificationsInfiniteScroll(
    hasMoreOlderNotifications,
    loadOlderNotifications,
  );

  return (
    <div
      data-qa='notifications_modal_content'
      className={cn(styles.content)}
      ref={listRef}
    >
      <div className={cn(styles.header, 'pb-12')}>
        <h1 className={cn(typography.platformH3, styles.title)}>
          {t(`${I18N_CODES.platformNotifications}:notifications_title`)}
        </h1>

        {!!unreadNotificationsCount && (
          <MarkNotificationsAsReadButton />
        )}
      </div>

      <NotificationsContentBody
        loading={loading}
        notifications={notifications}
        newerNotificationsTriggerRef={newerNotificationsTriggerRef}
      />
    </div>
  );
};
