import React, {
  type FC, memo, useCallback, useState,
} from 'react';
import { cn } from '@/lib/classNames';
import { IconStyling, LikeRatingContext } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingContext';
import styles from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingUI.module.scss';
import { Modal, PopUpTypeMode } from '@/components/ui/Modal';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { LikeIcon } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeIcon';
import { DislikeIcon } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/DislikeIcon';
import { useTranslation } from '@/middleware/i18n';
import { type RatingsFeedbackType } from '@/controllers/graphql/generated';
import { ReportProblemForm } from '@/components/platform/LikeRating/ReportProblemForm';
import { type FormValues } from '@/components/platform/LikeRating/ReportProblemForm.typedefs';
import { type SupportFragment } from '@/components/platform/Learn/graphql/generated/support.fragment.generated';

export enum IconSize {
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

interface Props {
  rating: number;
  onLike: () => void;
  onDisLike: () => void;
  onCommentUpdate: (comment: string, feedbackType: RatingsFeedbackType) => void;
  isModalOpen: boolean;
  onCloseModal: () => void;
  positiveRating?: number | null;
  negativeRating?: number | null;
  isCountersVisible: boolean;
  iconStyling: IconStyling;
  size: IconSize;
  shouldShowCommentModal?: boolean;
  initialFeedbackType?: RatingsFeedbackType;
  loading: boolean;
  buttonClassName?: string;
  support?: SupportFragment | null;
}

export const LikeRatingUI: FC<Props> = memo((props) => {
  const {
    rating,
    onLike,
    onDisLike,
    onCommentUpdate,
    isModalOpen,
    onCloseModal,
    positiveRating,
    negativeRating,
    isCountersVisible,
    iconStyling,
    size,
    shouldShowCommentModal = true,
    loading,
    buttonClassName,
  } = props;

  const [shouldShowComment, setShouldShowComment] = useState<boolean>(false);

  const openComment = useCallback(() => {
    setShouldShowComment(true);
  }, []);

  const submitCallback = useCallback(async (formData:FormValues) => {
    await onCommentUpdate(formData.comment, formData.feedbackType);
  }, [onCommentUpdate]);

  const { t } = useTranslation([I18N_CODES.likeRating]);

  return (
    <LikeRatingContext.Provider value={{ rating, iconStyling }}>
      <div
        className={styles.wrapper}
        data-qa="like-rating"
      >
        {shouldShowCommentModal && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={onCloseModal}
            type={PopUpTypeMode.System}
            renderTitle={() => (
              <div className={typography.platformH2}>
                {t(`${I18N_CODES.likeRating}:rating_modal_title`)}
              </div>
            )}
          >
            <p className={cn(typography.platformTextMain, 'pb-16')}>
              {t(`${I18N_CODES.likeRating}:rating_modal_text`)}
            </p>

            <ReportProblemForm
              loading={loading}
              submitCallback={submitCallback}
              shouldShowComment={shouldShowComment}
              openComment={openComment}
            />

          </Modal>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            mode={ButtonMode.TransparentLight}
            size={ButtonSize.Small}
            onClick={onLike}
            LeftIcon={LikeIcon}
            data-qa='notification-like-button'
            className={cn(
              styles.button,
              buttonClassName,
              {
                [styles.lightGrayStyling]:
                  iconStyling === IconStyling.LightGray,
                [styles.smallIcon]: size === IconSize.Small,
                [styles.largeIcon]: size === IconSize.Large,
              },
            )}
          />

          {isCountersVisible && (
            <span className={styles.ratingCounter}>{positiveRating || 0}</span>
          )}
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            mode={ButtonMode.TransparentLight}
            size={ButtonSize.Small}
            onClick={onDisLike}
            LeftIcon={DislikeIcon}
            className={cn(
              styles.button,
              buttonClassName,
              {
                [styles.lightGrayStyling]:
                  iconStyling === IconStyling.LightGray,
                [styles.smallIcon]: size === IconSize.Small,
                [styles.largeIcon]: size === IconSize.Large,
              },
            )}
          />

          {isCountersVisible && (
            <span className={styles.ratingCounter}>{negativeRating || 0}</span>
          )}
        </div>
      </div>
    </LikeRatingContext.Provider>
  );
});
