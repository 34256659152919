import React, {
  type Dispatch,
  type FC,
  memo,
  useCallback,
} from 'react';
import { cn } from '@/lib/classNames';
import { HorizontalSubscriptionPlan } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/HorizontalSubscriptionPlan';
import { Loader } from '@/components/ui/Loader';
import { type PaywallLocations, SubscriptionProductSources } from '@/controllers/analytics/generated';
import { IconCheck } from '@/components/ui/icons/IconCheck';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { PAID_PLAN_BENEFITS } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/constants';
import { typography } from '@/components/ui/typography';
import { analyticsSDK } from '@/controllers/analytics';
import { type CreateSubscriptionPayment } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/typedefs';
import { type SubscriptionPlanBaseFragment } from '@/components/platform/SubscriptionPlans/graphql/generated/subscriptionPlanBase.fragment.generated';
import { type SubscriptionPlanPricingOptionBaseFragment } from '@/components/platform/SubscriptionPlanPricingOptions/graphql/generated/subcsriptionPlanPricingOptionBase.fragment.generated';
import styles from './SubscriptionPlansList.module.scss';

interface Props {
  paywallLocation: PaywallLocations;
  pricingOptions: SubscriptionPlanPricingOptionBaseFragment[];
  selectedOption: SubscriptionPlanPricingOptionBaseFragment | null;
  setSelectedOption: Dispatch<SubscriptionPlanPricingOptionBaseFragment | null>;
  subscriptionPlans?: SubscriptionPlanBaseFragment[];
  openCheckout: () => void;
  plansLoading: boolean;
  createSubscriptionPayment: CreateSubscriptionPayment;
  paymentLoading: boolean;
}

export const SubscriptionPlansList: FC<Props> = memo(({
  paywallLocation,
  pricingOptions,
  selectedOption,
  setSelectedOption,
  openCheckout,
  plansLoading,
  paymentLoading,
  createSubscriptionPayment,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const handleSubscriptionPlanClick = useCallback(async () => {
    if (!selectedOption) {
      return;
    }

    analyticsSDK.subscriptionProduct.sendSubscriptionPlanSelectedEvent({
      subscriptionPlanName: selectedOption.subscriptionPlan.name,
      subscriptionType: selectedOption.subscriptionPlan.type,
      subscriptionPrice: selectedOption.subscriptionPlan.salePrice,
      subscriptionDuration: selectedOption.subscriptionPlan.periodDuration
        + selectedOption.subscriptionPlan.period,
      subscriptionPaymentType: selectedOption.subscriptionPlan.paymentType,
      pricingOptionName: selectedOption.name ?? undefined,
      pricingOptionSlug: selectedOption.slug,
      source: SubscriptionProductSources.Platform,
      paywallLocation,
    });

    await createSubscriptionPayment();

    openCheckout();
  }, [
    selectedOption,
    paywallLocation,
    createSubscriptionPayment,
    openCheckout,
  ]);

  return (
    <div className={styles.wrapper} data-qa='subscription-plans-list'>
      <div className={styles['subscriptionPlansList--vertical']}>
        {
          pricingOptions.map((option) => (
            <HorizontalSubscriptionPlan
              selectedOption={selectedOption}
              key={option.id}
              option={option}
              plan={option.subscriptionPlan}
              setSelectedOption={setSelectedOption}
              paywallLocation={paywallLocation}
            />
          ))
        }
      </div>

      <ul
        data-qa='subscription-plan-benefits'
        className={cn(styles.subscriptionPlanBenefits, 'color-gray-60')}
      >
        {PAID_PLAN_BENEFITS.map((benefit) => (
          <li key={benefit} className={styles.subscriptionPlanBenefit}>
            <div className={cn(styles.benefitIconWrapper)}>
              <IconCheck />
            </div>
            <span className={typography.platformTextSmall}>
              {t(`${I18N_CODES.common}:${benefit}`)}
            </span>
          </li>
        ))}
      </ul>

      <Button
        className={styles.cta}
        hasFullWidth
        onClick={handleSubscriptionPlanClick}
        mode={Button.mode.BrandPrimary}
        size={Button.size.Large}
        data-qa='paywall-continue-cta-button'
        text={t(`${I18N_CODES.common}:paywall.continue_cta_button`)}
        isLoading={paymentLoading}
      />

      <Loader loading={plansLoading} />
    </div>
  );
});
