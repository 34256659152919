import ukFlag from '@/images/generated/icons.emoji.ukFlag.s3Key';
import { type FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { IconImage } from '@/components/ui/Image/IconImage';

export const IconEmojiFlagUK: FCImage = ({
  size = 56,
  className,
  alt = 'Flag United Kingdom',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={ukFlag}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
