import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconFireEmpty: FCIcon = (props) => (
  <BaseIcon
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1272_6391)">
      <ellipse cx="9.55114" cy="9.75729" rx="2.62561" ry="3.48238" />
      <path d="M13.7384 7.99941C12.6391 5.2931 8.72525 5.14718 9.67046 1.21374C9.74048 0.921885 9.41141 0.696359 9.14534 0.848921C6.60376 2.26841 4.77634 5.11401 6.30969 8.84182C6.43572 9.14694 6.05763 9.43216 5.78457 9.23317C4.51728 8.32443 4.38425 7.01771 4.49627 6.08244C4.53828 5.73752 4.06217 5.57169 3.85912 5.85692C3.38301 6.54676 2.8999 7.66112 2.8999 9.3393C3.16596 13.0538 6.47773 14.1947 7.668 14.3407C9.3694 14.5463 11.2108 14.2478 12.5341 13.1003C13.9905 11.8201 14.5226 9.77709 13.7384 7.99941ZM7.2409 11.3359C8.24914 11.1037 8.76726 10.4139 8.90729 9.80362C9.13834 8.85508 8.23513 7.92645 8.84427 6.42737C9.07533 7.66776 11.1338 8.44383 11.1338 9.79699C11.1898 11.4752 9.27137 12.9145 7.2409 11.3359Z" fill='currentColor' />
    </g>
    <defs>
      <clipPath id="clip0_1272_6391">
        <rect width="16" height="16" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
