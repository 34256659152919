import React, {
  createContext, type FC, useContext,
} from 'react';
import { CourseTopicMode, type SupportProviderName } from '@/controllers/graphql/generated';
import { emptyFunction } from '@/lib/helpers/functional';

export interface CourseTopicContextType {
  isMobileWidth: boolean;
  isCourseTopicModuleBasic?: boolean;
  supportLink?: string | null;
  supportProviderName?: SupportProviderName | null;
  courseUserTopicId: number;
  courseTopicId: number;
  courseTopicOrder: number;
  courseSlug?: string | null;
  topicMode: CourseTopicMode;
  shouldShowModuleCompletionModal?: boolean;
  parentCourseId?: number;
  parentCourseSlug?: string;
  aiChat?: {
    isOpen: boolean;
    close: () => void;
    open: () => void;
  };
  courseUserTopicProgress?: number;
  courseTopicName?: string;
}

export const CourseTopicContext = createContext<CourseTopicContextType>({
  isMobileWidth: false,
  courseUserTopicId: 0,
  courseTopicId: 0,
  courseTopicOrder: 0,
  courseSlug: null,
  topicMode: CourseTopicMode.Regular,
  isCourseTopicModuleBasic: false,
  supportProviderName: null,
  supportLink: null,
  shouldShowModuleCompletionModal: false,
  parentCourseId: 0,
  parentCourseSlug: '',
  aiChat: {
    isOpen: false,
    close: emptyFunction,
    open: emptyFunction,
  },
  courseTopicName: '',
  courseUserTopicProgress: 0,
});

export const CourseTopicContextProvider: FC<CourseTopicContextType> = (
  ({ children, ...props }) => (
    <CourseTopicContext.Provider
      value={props}
    >
      {children}
    </CourseTopicContext.Provider>
  )
);

export const useCourseTopicContext: () => CourseTopicContextType = () => (
  useContext(CourseTopicContext)
);
