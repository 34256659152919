import React, { memo, useCallback } from 'react';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import {
  type PracticeTabs,
} from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/PracticeTabs.constants';
import { redirect } from '@/lib/redirect';
import { useLearningRootForRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './MobileHeaderBackButton.module.scss';

export interface QueryParams {
  taskSection: PracticeTabs | null;
  eventId: number;
}
export const MobileHeaderBackButton = memo(() => {
  const { t } = useTranslation([I18N_CODES.learn]);

  const {
    push,
    pathname,
  } = useRouter();

  const {
    taskSection,
    eventId,
  } = useRouterQuery<QueryParams>();
  const learningRoot = useLearningRootForRedirect();

  const handleBackLink = useCallback(() => {
    if (taskSection) {
      push({
        pathname,
        query: {
          eventId,
        },
      });
    } else {
      redirect({}, learningRoot);
    }
  }, [push, eventId, pathname, taskSection, learningRoot]);

  const buttonText = taskSection
    ? t(`${I18N_CODES.learn}:go_to_tasks`)
    : t(`${I18N_CODES.learn}:back_to_learn_link`);

  return (
    <div className={styles.buttonWrapper}>
      <Button
        text={buttonText}
        LeftIcon={IconArrowLeft}
        mode={ButtonMode.TransparentLight}
        size={ButtonSize.Small}
        onClick={handleBackLink}
      />
    </div>
  );
});
