import React, {
  type FC,
  useCallback,
  useMemo,
} from 'react';
import dynamic from 'next/dynamic';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { IconFire } from '@/components/ui/icons/IconFire';
import { Button } from '@/components/ui/Button';
import { cn } from '@/lib';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Dropdown } from '@/components/common/Dropdown';
import { StreaksModalContent } from '@/components/platform/Streaks/components/StreaksModalContent';
import { analyticsSDK } from '@/controllers/analytics';
import { useActiveUserStreakQuery } from '@/components/platform/Streaks/graphql/generated/activeUserStreak.query.generated';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { StudentStatus } from '@/controllers/graphql/generated';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { useWindowSize } from '@/hooks/useWindowSize';
import {
  ChubbsApplying,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying';
import { Selectors } from '@/lib/selectors';
import styles from '@/components/platform/Streaks/components/StreaksHeaderWidget/StreaksHeaderWidget.module.scss';
import { useUserStreaksUpdated } from '@/components/platform/Streaks/hooks/useUserStreaksUpdated';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

const InfoPopover = dynamic(
  () => import('@/components/ui/InfoPopover')
    .then((mod) => mod.InfoPopover),
  { ssr: true },
);

interface Props {
  isMobilePageOpened?: boolean;
}

export const StreaksHeaderWidget: FC<Props> = ({
  isMobilePageOpened,
}) => {
  const { t } = useTranslation([
    I18N_CODES.common,
    I18N_CODES.learn,
    I18N_CODES.vacancies,
  ]);
  const [authUser] = useAuthUser({ ssr: true });

  const router = useRouter();

  const {
    data: activeUserStreakResult,
    loading: loadingActiveUserStreak,
  } = useActiveUserStreakQuery();

  useUserStreaksUpdated();

  const activeStreak = useMemo(
    () => activeUserStreakResult?.activeUserStreak || null,
    [activeUserStreakResult],
  );

  const lastStreakActiveDays = activeStreak?.activeDaysCount || 0;

  const studentIsOnEmploymentStage = (
    authUser?.studentStatus === StudentStatus.Employment
  );

  const countActiveStreakDay = `number-of-active-streak-days-${lastStreakActiveDays}`;

  const [
    hasSeenPopover,
    setHasSeenPopover,
  ] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.hasSeenApplyingStreaksPopover,
    false,
  );

  const shouldShowStreakPopover = (
    activeStreak
    && !loadingActiveUserStreak
    && !hasSeenPopover
    && studentIsOnEmploymentStage
  );

  const dropdownRenderContent = useCallback(({ isActive }) => (
    <div
      className={cn(
        styles.streaksModal,
        { [Selectors.DropdownOpened]: isActive },
      )}
      data-qa="streaks_modal"
    >
      {isActive && (
        <StreaksModalContent
          activeStreak={activeStreak}
          isActive={isActive}
        />
      )}
    </div>
  ), [activeStreak]);

  const [width] = useWindowSize();

  const sendEvent = useCallback(() => {
    analyticsSDK.streaks.sendWidgetOpenedEvent({});
  }, []);

  const dropdownRenderTrigger = useCallback((onClick) => {
    const isMobile = width < MINIMAL_TABLET_WIDTH;

    const buttonAction = isMobile
      ? () => router.push(renderRoute(allRoutes.streaks))
      : onClick;

    const buttonText = isMobile
      ? String(lastStreakActiveDays)
      : t(`${I18N_CODES.common}:active_days_count`, { count: lastStreakActiveDays });

    return (
      <Button
        mode={Button.mode.TransparentLight}
        LeftIcon={IconFire}
        onClick={buttonAction}
        className={cn(styles.streaksButton, {
          [styles.pageOpenedState]: isMobilePageOpened,
        })}
        text={buttonText}
        disabled={isMobilePageOpened}
        data-qa={countActiveStreakDay}
      />
    );
  }, [
    countActiveStreakDay,
    isMobilePageOpened,
    lastStreakActiveDays,
    router,
    t,
    width,
  ]);

  return (
    <div className={styles.wrapper} data-qa="streaks_block">
      <Dropdown
        isFullWidth
        sendEvent={sendEvent}
        renderTrigger={dropdownRenderTrigger}
        renderContent={dropdownRenderContent}
      />

      {
        shouldShowStreakPopover && (
          <InfoPopover
            className={styles.popover}
            header={(
              <div className={styles.chubbsWrapper} data-qa='chubbs-wrapper'>
                <ChubbsApplying
                  width={154}
                  height={112}
                  className={styles.chubbs}
                />
              </div>
            )}
            title={t(`${I18N_CODES.learn}:vacancyStreak.title`)}
            text={t(`${I18N_CODES.learn}:vacancyStreak.text`)}
            buttonAction={() => setHasSeenPopover(true)}
            buttonText={t(`${I18N_CODES.learn}:streaksPopover.button`)}
          />
        )
      }

      <Divider referral={DividerReferrals.Vertical} />
    </div>
  );
};
