import React, { type FC } from 'react';
import { type SearchableEntityType } from '@/controllers/graphql/generated';
import { Button } from '@/components/ui/Button';
import { IconClose } from '@/components/ui/icons/IconClose';
import { getTranslateCodeByCategory } from '@/components/platform/GlobalSearch/helpers';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './SelectedCategoryTag.module.scss';

interface Props {
  category: SearchableEntityType;
  onClick: () => void;
}

export const SelectedCategoryTag: FC<Props> = ({
  category,
  onClick,
}) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);

  return (
    <Button
      onClick={onClick}
      text={t(getTranslateCodeByCategory(category))}
      mode={Button.mode.TransparentLight}
      RightIcon={IconClose}
      className={styles.tag}
    />
  );
};
