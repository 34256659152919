import { CourseType } from '@/controllers/graphql/generated';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';

export const isFlexStudent = (
  user: Pick<AuthUserFragment, 'courseType' | 'studentsGroup' | 'studentStatus'> | null,
): boolean => (
  user?.courseType === CourseType.Prepaid
    && Boolean(user?.studentsGroup)
    && Boolean(user?.studentStatus)
);
