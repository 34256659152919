import React, {
  type FC, useState, useCallback, useEffect, memo,
} from 'react';
import { SelectUi } from '@/components/ui/FormElements/FormInputs/Select';
import { useDebounce } from '@/hooks/useDebounce';
import { useUserFilterOptions } from '@/components/platform/GlobalSearch/hooks/useUserFilterOptions';
import { type FileBaseFragment } from '@/controllers/files/File/generated/FileBase.fragment.generated';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { UserProfileOpener } from '@/components/platform/UserProfile/typedefs';
import styles from './UserFilter.module.scss';

interface Props {
  setUserId: (userId: number | null) => void;
}

interface UserOption {
  value: string;
  label: {
    id: number;
    username: string;
    avatar?: FileBaseFragment | null;
  };
}

const DEBOUNCE = 250;

export const UserFilter: FC<Props> = memo(({ setUserId }) => {
  const {
    getUserOptions,
    userOptions,
    loading,
  } = useUserFilterOptions();

  const [userQuery, setUserQuery] = useState('');
  const [debouncedUserQuery] = useDebounce(userQuery, DEBOUNCE);

  const onChange = useCallback(
    (option?: UserOption | null) => {
      if (option?.value) {
        setUserId(Number(option.value));
      } else {
        setUserId(null);
      }
    },
    [setUserId],
  );

  const userOptionRendered = useCallback(({ label }) => (
    <div className={styles.userOption}>
      <UserAvatar
        userId={label.id}
        avatar={label.avatar}
        fullName={label.username}
        userProfileOpener={UserProfileOpener.GlobalSearch}
      />

      {label.username}
    </div>
  ), []);

  const extractOptionLabel = useCallback((option: UserOption) => (
    option.label.username
  ), []);

  useEffect(() => {
    if (debouncedUserQuery) {
      getUserOptions(debouncedUserQuery);
    }
  }, [debouncedUserQuery, getUserOptions]);

  return (
    <SelectUi<UserOption>
      inputValue={userQuery}
      onInputChange={setUserQuery}
      onChange={onChange}
      options={userOptions}
      isLoading={loading}
      getOptionLabel={extractOptionLabel}
      formatOptionLabel={userOptionRendered}
      isClearable
      placeholder="From: Select user"
    />
  );
});
