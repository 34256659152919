import React, { type FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { type ToggleSidebarMutationVariables } from '@/controllers/platform/sidebar/sidebar.typedefs';
import { IconBurger } from '@/components/ui/icons/IconBurger';
import { IconClose } from '@/components/ui/icons/IconClose';
import styles from '@/components/platform/SidebarHeader/components/SidebarToggle.module.scss';

interface Props {
  isBurgerVisible?: boolean;
  sidebarOpen: boolean;
  toggleSidebar: (args: ToggleSidebarMutationVariables) => void;
}

export const SidebarToggleComponent: FC<Props> = memo((props) => {
  const {
    isBurgerVisible,
    sidebarOpen,
    toggleSidebar,
  } = props;

  const { t } = useTranslation([I18N_CODES.platformSidebar]);

  return (
    <button
      type="button"
      title={t(`${I18N_CODES.platformSidebar}:sidebar_toggle`)}
      aria-label={t(`${I18N_CODES.platformSidebar}:sidebar_toggle`)}
      className={cn(styles.container, {
        [styles.containerActive]: sidebarOpen,
        [styles.containerHidden]: !isBurgerVisible,
      })}
      onClick={() => {
        toggleSidebar({
          open: !sidebarOpen,
        });
      }}
    >
      <span className={cn(styles.icon, styles.burgerIcon)}>
        <IconBurger />
      </span>
      <span className={cn(styles.icon, styles.toggleIcon)}>
        <IconClose />
      </span>
    </button>
  );
});
