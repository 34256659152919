import React, { type DetailedHTMLProps, memo } from 'react';

export type AProps = DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement
>;

export const A = memo<AProps>(({
  children,
  href,
  onClick,
}) => (
  <a
    href={href}
    target={href?.includes('http')
      ? '_blank'
      : '_self'}
    rel="noreferrer"
    onClick={onClick}
  >
    {children}
  </a>
));
