import React, { type FC } from 'react';
import { useCopyTextAsHTML } from '@/components/platform/Chat/hooks/useCopyTextAsHTML';

export const CopyAsHTML: FC = ({ children }) => {
  const onCopy = useCopyTextAsHTML();

  return (
    <div onCopy={onCopy}>
      {children}
    </div>
  );
};
