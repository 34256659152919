import { type FC, type ReactNode } from 'react';
import { IconFireworkBase } from '@/components/ui/icons/IconFireworkBase';
import { colorTokens } from '@/components/ui/colorTokens';
import { cn } from '@/lib';
import styles from './FireworkBackground.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

export const FireworkBackground: FC<Props> = ({
  className = '',
  children,
}) => (
  <div className={cn(styles.wrapper, className)}>
    {children}

    <IconFireworkBase
      color1={colorTokens['fill-info-bold']}
      color2={colorTokens['icon-accent-purple']}
      className={styles.firework1}
    />

    <IconFireworkBase
      color1={colorTokens['fill-brand-bold']}
      color2={colorTokens['icon-accent-magenta']}
      className={styles.firework2}
    />

    <IconFireworkBase
      color1={colorTokens['fill-danger-bold']}
      color2={colorTokens['fill-warning-bold']}
      className={styles.firework3}
    />

    <IconFireworkBase
      color1={colorTokens['fill-accent-lime-bold']}
      color2={colorTokens['icon-accent-teal']}
      className={styles.firework4}
    />

    <IconFireworkBase
      color1={colorTokens['fill-danger-bold']}
      color2={colorTokens['fill-warning-bold']}
      className={styles.firework5}
    />

    <IconFireworkBase
      color1={colorTokens['fill-info-bold']}
      color2={colorTokens['icon-accent-purple']}
      className={styles.firework6}
    />

    <IconFireworkBase
      color1={colorTokens['fill-brand-bold']}
      color2={colorTokens['icon-accent-magenta']}
      className={styles.firework7}
    />

    <IconFireworkBase
      color1={colorTokens['fill-accent-lime-bold']}
      color2={colorTokens['icon-accent-teal']}
      className={styles.firework8}
    />
  </div>
);
